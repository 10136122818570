import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { _PDP_ } from './global.js';

export const StatisticsViewer = (props) => {
    
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [questions, setQuestions] = useState([]);

  const [editingRow, setEditingRow] = useState(null);  
  const [updatedData, setUpdatedData] = useState(questions); // where updatedData is the whole users array; make sure fill with questions otherwise updatedData.map will fail

  const [newYear, setNewYear] = useState('');
  const [newMonth, setNewMonth] = useState('');
  const [newWebclicks, setNewWebclicks] = useState('');
  const [newTime, setNewTime] = useState('');

  const [newQuestions, setNewQuestions] = useState('');
  const [newAnswers, setNewAnswers] = useState('');
  const [newImages, setNewImages] = useState('');    
  const [newOffianswers, setNewOffianswers] = useState('');    
  const [newFixedcost, setNewFixedcost] = useState('');  
  const [newVariablecost, setNewVariablecost] = useState('');
  const [newAveragecost, setNewAveragecost] = useState('');
  const [newBroadcasts, setNewBroadcasts] = useState('');
  const [newUnsubscribes, setNewUnsubscribes] = useState('');
  const [newDistributors, setNewDistributors] = useState('');
  const [newRefcount, setNewRefcount] = useState('');
  const [newRegcount, setNewRegcount] = useState('');
  const [newPaycount, setNewPaycount] = useState('');
  const [newCntotal, setNewCntotal] = useState('');
  const [newUstotal, setNewUstotal] = useState('');
  const [newCatotal, setNewCatotal] = useState('');    
  const [newBalancetotal, setNewBalancetotal] = useState('');  

  const setEditingRowById  = id => {
    const row = updatedData.find(row => row.id === id);

    setNewYear(row.year);
    setNewMonth(row.month);
    setNewWebclicks(row.webclicks);
    setNewTime(row.time);
    setNewQuestions(row.questions);
    setNewAnswers(row.answers);
    setNewImages(row.images);
    setNewOffianswers(row.offianswers);        
    setNewFixedcost(row.fixedcost);    
    setNewVariablecost(row.variablecost);
    setNewAveragecost(row.averagecost);
    setNewBroadcasts(row.broadcasts);
    setNewUnsubscribes(row.unsubscribes);
    setNewDistributors(row.distributors);
    setNewRefcount(row.refcount);
    setNewRegcount(row.regcount);
    setNewPaycount(row.paycount);
    setNewCntotal(row.cntotal);
    setNewUstotal(row.ustotal);  
    setNewCatotal(row.catotal);  
    setNewBalancetotal(row.balancetotal);      
  
    setEditingRow(id);
  };

  const handleSaveClick = async (id, newYear, newMonth, newWebclicks, newTime, newQuestions, newAnswers, newImages, newOffianswers, newFixedcost, newVariablecost, newAveragecost, newBroadcasts, newUnsubscribes, newDistributors, newRefcount, newRegcount, newPaycount, newCntotal, newUstotal, newCatotal, newBalancetotal, props ) => {
    const newData = [...updatedData];
    const index = newData.findIndex(row => row.id === id);
    const oldRow = newData[index];
    const newRow = { ...oldRow, year: newYear, month: newMonth, webclicks: newWebclicks, time: newTime, questions: newQuestions, answers: newAnswers, images: newImages, offianswers: newOffianswers, fixedcost: newFixedcost, variablecost: newVariablecost, averagecost: newAveragecost, broadcasts: newBroadcasts, unsubscribes: newUnsubscribes, distributors: newDistributors, refcount: newRefcount, regcount: newRegcount, paycount: newPaycount, cntotal: newCntotal, ustotal: newUstotal, catotal: newCatotal, balancetotal: newBalancetotal };
    if (      
      newRow.year !== oldRow.year || 
      newRow.month !== oldRow.month || 
      newRow.webclicks !== oldRow.webclicks || 
      newRow.time !== oldRow.time || 
      newRow.questions !== oldRow.questions || 
      newRow.answers !== oldRow.answers || 
      newRow.images !== oldRow.images ||       
      newRow.offianswers !== oldRow.offianswers ||             
      newRow.fixedcost !== oldRow.fixedcost ||       
      newRow.variablecost !== oldRow.variablecost || 
      newRow.averagecost !== oldRow.averagecost || 
      newRow.broadcasts !== oldRow.broadcasts || 
      newRow.unsubscribes !== oldRow.unsubscribes || 
      newRow.distributors !== oldRow.distributors ||    
      newRow.refcount !== oldRow.refcount ||      
      newRow.regcount !== oldRow.regcount ||      
      newRow.paycount !== oldRow.paycount ||      
      newRow.cntotal !== oldRow.cntotal ||      
      newRow.catotal !== oldRow.catotal ||
      newRow.ustotal !== oldRow.ustotal ||
      newRow.balancetotal !== oldRow.balancetotal) 
      {
      newData[index] = newRow;
      setUpdatedData(newData);
      setEditingRow(null);

      // where newRow is updated to be pushed to sever

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      };      

      const data =
      {    
        id: newRow.id,        
        year: newRow.year,
        month: newRow.month,
        webclicks: newRow.webclicks,
        time: newRow.time,
        questions: newRow.questions,
        answers: newRow.answers,
        offianswers: newRow.offianswers,                
        fixedcost: newRow.fixedcost,        
        variablecost: newRow.variablecost,
        averagecost: newRow.averagecost,
        broadcasts: newRow.broadcasts,
        unsubscribes: newRow.unsubscribes,
        distributors: newRow.distributors,    
        refcount: newRow.refcount,
        regcount: newRow.regcount,
        paycount: newRow.paycount,
        cntotal: newRow.cntotal,
        ustotal: newRow.ustotal,
        catotal: newRow.catotal,
        balancetotal: newRow.balancetotal                
      };  
      
      try {    
          const endpoint = `${_PDP_}/api/save-row-statistic`;
          const response = await axios.post(endpoint, data, { headers: headers });
          console.log(response.data);
          setSuccess(response.data.message);
          setError(null);
          props.setBalance(response.data.balance);          

        } catch (error) {
          setError(error.response.data.message);
          setSuccess(null);
        } 
      
      }
  };  


  const renderRow = row => {
    const { id } = row;
    if (editingRow === id) {
      return (
        <tr key={id}>
          <td>{id}</td>          

          <td><input className="input2" type="text" value={newYear} onChange={e => setNewYear(e.target.value)} /></td> 
          <td><input className="input2" type="text" value={newMonth} onChange={e => setNewMonth(e.target.value)} /></td> 
          <td><input className="input2" type="number" value={newWebclicks} onChange={e => setNewWebclicks(e.target.value)} /></td>
          <td><input className="input2" type="text" value={newTime} onChange={e => setNewTime(e.target.value)} /></td>      
          <td><input className="input2" type="number" value={newQuestions} onChange={e => setNewQuestions(e.target.value)} /></td>
          <td><input className="input2" type="number" value={newAnswers} onChange={e => setNewAnswers(e.target.value)} /></td>
          <td><input className="input2" type="number" value={newImages} onChange={e => setNewImages(e.target.value)} /></td>          
          <td><input className="input2" type="number" value={newOffianswers} onChange={e => setNewOffianswers(e.target.value)} /></td>                    
          <td><input className="input2" type="number" value={newFixedcost} onChange={e => setNewFixedcost(e.target.value)} /></td>
          <td><input className="input2" type="number" value={newVariablecost} onChange={e => setNewVariablecost(e.target.value)} /></td>
          <td><input className="input2" type="number" value={newAveragecost} onChange={e => setNewAveragecost(e.target.value)} /></td>
          <td><input className="input2" type="number" value={newBroadcasts} onChange={e => setNewBroadcasts(e.target.value)} /></td>
          <td><input className="input2" type="number" value={newUnsubscribes} onChange={e => setNewUnsubscribes(e.target.value)} /></td>    
          <td><input className="input2" type="number" value={newDistributors} onChange={e => setNewDistributors(e.target.value)} /></td>
          <td><input className="input2" type="number" value={newRefcount} onChange={e => setNewRefcount(e.target.value)} /></td>                    
          <td><input className="input2" type="number" value={newRegcount} onChange={e => setNewRegcount(e.target.value)} /></td>           
          <td><input className="input2" type="number" value={newPaycount} onChange={e => setNewPaycount(e.target.value)} /></td>          
          <td><input className="input2" type="number" value={newCntotal} onChange={e => setNewCntotal(e.target.value)} /></td>
          <td><input className="input2" type="number" value={newUstotal} onChange={e => setNewUstotal(e.target.value)} /></td>
          <td><input className="input2" type="number" value={newCatotal} onChange={e => setNewCatotal(e.target.value)} /></td>
          <td><input className="input2" type="number" value={newBalancetotal} onChange={e => setNewBalancetotal(e.target.value)} /></td>             
          <td>
            <button className="save" onClick={() => handleSaveClick(id, newYear, newMonth, newWebclicks, newTime, newQuestions, newAnswers, newImages, newOffianswers, newFixedcost, newVariablecost, newAveragecost, newBroadcasts, newUnsubscribes, newDistributors, newRefcount, newRegcount, newPaycount, newCntotal, newUstotal, newCatotal, newBalancetotal, props)}>Save</button>
            <button className="cancel" onClick={() => setEditingRow(null)}>Cancel</button>            
          </td>

        </tr>
      );
    } else {
      return (
        <tr key={id}>
          <td>{row.id}</td>
          <td>{row.year}</td>
          <td>{row.month}</td>
          <td>{row.webclicks}</td>
          <td>{row.time}</td>
          <td>{row.questions}</td>          
          <td>{row.answers}</td>
          <td>{row.images}</td>          
          <td>{row.offianswers}</td>
          <td>{row.fixedcost}</td>
          <td>{row.variablecost}</td> 
          <td>{row.averagecost}</td>
          <td>{row.broadcasts}</td>
          <td>{row.unsubscribes}</td>
          <td>{row.distributors}</td>
          <td>{row.refcount}</td>
          <td>{row.regcount}</td>
          <td>{row.paycount}</td>
          <td>{row.cntotal}</td>
          <td>{row.ustotal}</td>
          <td>{row.catotal}</td>
          <td>{row.balancetotal}</td>
          <td>
            <button className="edit" onClick={() => setEditingRowById(id)}>Edit</button>            
          </td>
        </tr>
      );
    }
  };
  
  useEffect(() => {
    fetchRecords();
  }, []);


  const fetchRecords = async () => {

    const endpoint = `${_PDP_}/api/statistics` ;
    const response = await fetch (endpoint, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem('token')}`, // include token in header        
      }
    });    

    if (response.ok) {
      const data = await response.json();      
      console.log(data.message);
      setSuccess(data.message);        
      setError(null);        
      setQuestions(data);
      setUpdatedData(data);      
    } else {
      // handle error
      const msgToUser = `Please log in to get the required authentication. Access Status: ${response.status} - ${response.statusText}.`;      
      console.log(msgToUser);
      setSuccess(null);        
      setError(msgToUser);        
    }        
  };      

  const refreshRecords = () => {
    fetchRecords();
  }

  return (
    <div className="auth-form-container">
    <h3>Statistics Viewer</h3>   
    {error && <div className="error">{error}</div>}
    {success && <div className="success">{success}</div>}

    <div className="table-container">
      <table className="table-form">
        <thead>
          <tr>
            <th>ID</th>
            <th>Year</th>
            <th>Month</th>
            <th>Webclicks</th>
            <th>Time</th>
            <th>Questions</th>
            <th>Answers</th>
            <th>Images</th>            
            <th>Offianswers</th>
            <th>Fixedcost($CAD)</th>
            <th>Variablecost($USD)</th>
            <th>Averagecost($CAD)</th>
            <th>Broadcasts</th>
            <th>Unsubscribes</th>
            <th>Distributors</th>
            <th>Refcount</th>
            <th>Regcount</th>
            <th>Paycount</th>
            <th>Stripe Cntotal(¥CNY)</th>
            <th>Stripe Ustotal($USD)</th>
            <th>CIBC Catotal($CAD)</th>
            <th>Balancetotal</th>                        
          </tr>
        </thead>
        <tbody>
          {updatedData.map(renderRow)}
        </tbody>
      </table>
    </div>
    <div style={{ marginTop: '20px' }}>
      <button className="link-btn" onClick={refreshRecords}>Refresh</button>                 
      <button className="link-btn" onClick={()=>props.onFormSwitch('login')}>Login</button>             
    </div>
    </div>
    );  
}