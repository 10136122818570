import React, { useState, useEffect, useLayoutEffect } from 'react';
import axios from 'axios';
import { _PDP_ } from './global.js';

import { useContext } from 'react';

export const Spintrol = (props) => {

    // In your functional component:
    
    const [file, setFile] = useState('');
    const [token, setToken] = useState('');    
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [warning, setWarning] = useState(null);    
    const [isLoggedIn, setIsLoggedIn] = useState(0);    
    const [isLoggedInServer, setLoggedInServer] = useState(0);                
    const [isReady, setIsReady] = useState(false); // Add a new state variable    

    function checkLocalStorage() {
      if (localStorage.getItem('token') !== null) {
        setIsLoggedIn(1);
        setToken(localStorage.getItem('token'));
      } else {
        setIsLoggedIn(0);
      }

      // override with server data
      // setIsLoggedIn(isLoggedInServer)
    }    
    
    // 1)It is possible that the useEffect hook is not being called before the component renders for the first time, which can cause the login status to be displayed incorrectly.
    // To ensure that the useEffect hook is called before the component renders for the first time, you can add a state variable to indicate whether the check has been completed, and use that variable to conditionally render the component.    
    // 2) It's possible that the issue is caused by a race condition between the useEffect hook and the component rendering. To fix this, you can try using the useLayoutEffect hook instead of useEffect. useLayoutEffect runs synchronously after all DOM mutations, which can help avoid race conditions.    
    useLayoutEffect(() => {
      checkLocalStorage();  
      setIsReady(true); // Set the new state variable when the check is completed      
    }, []);
    
    if (!isReady) {
      return null; // Render nothing until the check is completed
    }

    const handleAuthButtonClick = (e) => {
      e.preventDefault();

      console.log(`handleAuthButtonClick called, isLoggedIn= ${isLoggedIn}`);      

      if (isLoggedIn) {
        handleLogout(e);
      } else {
        handleLogin(e);
      }
    };    

    const handleLogout = async (e) => {
      console.log("handleLogout called");
      
      try {
        const response = await fetch(`${_PDP_}/api/logout`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email, pass, token }),
        });

        const data = await response.json();
    
        if (response.ok) {
          localStorage.removeItem('token');          // remove token from local storage
          localStorage.removeItem('existingEmail');  // remove existingEmail from local storage          
          setIsLoggedIn(0);
          setWarning(`Attention: ${data.message}`);
          setSuccess(null);
          setError(null);

          // reset the logged out user
          props.setLoggedInUser(null);
          props.setBalance(null); 

          // do something with the user data, e.g. redirect to home page  
          // history.push('/');
        } else {
          setError(`Logout failed 登出失败: ${data.message}`); 
          setSuccess(null); 
          setWarning(null); 
          // Allow Login again when Logout failed
          localStorage.removeItem('token');          // remove token from local storage          
          setIsLoggedIn(0);
        }


      } catch (error) {
        console.error(error);
        // show error message to user
        setError(error.message);
      }
    };    

    // const history = useHistory();  

    const handleLogin  = async (e) => {
        e.preventDefault();
        console.log(email);

        // Check if email is valid - The overall pattern ensures that the email address has at least one character before the "@" symbol, at least one character between the "@" symbol and the period, and at least one character after the period. It also ensures that there are no spaces anywhere in the email address.
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!regex.test(email)) {
          setError('Please enter a valid email address 请输入有效邮箱地址');
          setSuccess(null);
          setWarning(null);
          return;
        }          
        
        try {
            const response = await fetch(`${_PDP_}/api/login`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ email, pass }),
            });

            const data = await response.json();
        
            if (response.ok) {
              localStorage.setItem('token', data.token); // save token to local storage
              localStorage.setItem('existingEmail', data.existingEmail); // save existingEmail to local storage              
              setIsLoggedIn(1);
              setSuccess(`Success 成功: ${data.message}`); 
              setError(null);              
              setWarning(null);    

              // Set the logged in user
              props.setLoggedInUser(email);
              props.setBalance(data.balance);

              // do something with the user data, e.g. redirect to home page  
              // history.push('/');
            } else {
              setError(`Login failed 登录失败: ${data.message}`); 
              setSuccess(null); 
              setWarning(null);              
            }


          } catch (error) {
            console.error(error);
            // show error message to user
            setError(error.message);
          }

      };    
      
      const getLoggedIn = async (props) => {

        const email = props.loggedInUser || localStorage.getItem('existingEmail') || "";

        try {
          const endpoint = `${_PDP_}/api/userLoggedIn?email=${email}`;
          const response = await axios.get(endpoint);
          setLoggedInServer(response.data.loggedIn);
          // return here to avoid Object promise of userID
          return parseInt(response.data.loggedIn);
        } catch (error) {
          console.error(error);          
        }
      };      


      const handleFileUpload = (event) => {
        event.preventDefault();
        // const file = event.target?.files?.[0];        
        // alert(file.name);        
    
        if (!file) {
          setWarning('Please select a file to upload');
          setSuccess(null);
          setError(null);
          return;
        }
    
        const formData = new FormData();
        formData.append('file', file);
    
        const endpoint = `${_PDP_}/api/upload-file`;
        axios.post(endpoint, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(response => {
            if (response.data.success) {
              setSuccess(response.data.message);
              setError(null);
              setWarning(null);
            } else {
              setError(response.data.message);
              setSuccess(null);
              setWarning(null);              
            }
          })
          .catch(error => {
            console.log(error);
            setError('An error occurred while uploading the file.');
            setSuccess(null);
            setWarning(null);            
          });
      };      


    const onChange = (e) =>
    {
      let myfile = e.target?.files?.[0];
      // alert(myfile.name);
      setFile(myfile);
    }

    // Render the component when the check is completed      
    return (
        <div className="auth-form-container">
            <h3>Spintrol 旋智科技</h3>   
            {error && <div className="error">{error}</div>}
            {success && <div className="success">{success}</div>}
            {warning && <div className="warning">{warning}</div>} 
            <form className="login-form" onSubmit={handleLogin}>
                <label htmlFor ="email">Email 邮箱:</label>
                <input value={email} onChange={(e)=>setEmail(e.target.value)} type="email" placeholder="youremail@qq.com" id="email" name="email" required/>
                <label htmlFor ="password">Password 密码:</label>
                <input value={pass} onChange={(e)=>setPass(e.target.value)} type="password" placeholder="********" id="password" name="password" required/>         

                <button className={`auth-form-button ${isLoggedIn ? 'logout' : 'login'}`} type="button" onClick={handleAuthButtonClick} > {isLoggedIn ? 'Logout 登出' : 'Login 登录'} </button>                
            </form>

              {isLoggedIn ? (
                <div style={{ display: 'flex', flexDirection: 'column' }}>

                  <button className="link-btn" onClick={()=>props.onFormSwitch('login')}>Login 登录</button>

                  {(email.includes("georgesjiang@gmail.com") || email.includes("ai@spintrol.com")) && (
                    <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'column' }}>
                      <button className="HarvardCrimson-link-btn" onClick={()=>props.onFormSwitch('usermanualsviewer')}>User Manuals Viewer 用户手册一览</button>

                      <form onSubmit={handleFileUpload}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <input type="file" name="file" onChange={onChange}  style={{ marginBottom: '10px' }}/>
                          <button type="submit" style={{ width: '140px', backgroundColor: '#007bff', color: '#fff', borderRadius: '5px', padding: '10px', border: 'none', marginLeft: '15px' }}>Upload File 上传文件</button>
                        </div>
                      </form> 

                    </div>
                  )}

                </div>
              ) : (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <button className="link-btn" onClick={()=>props.onFormSwitch('forgotpassword')}>Forgot Password 忘记密码</button>            
                  <button className="link-btn" onClick={()=>props.onFormSwitch('register')}>Register 注册</button>            
                </div>
              )}

        </div>
    );
}

