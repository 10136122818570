import React, { useState, useEffect } from "react";
import axios from 'axios';
import { _PDP_ } from './global.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWeixin } from '@fortawesome/free-brands-svg-icons'
import { faAlipay } from '@fortawesome/free-brands-svg-icons';

import { loadStripe } from '@stripe/stripe-js';

const MinAmount = 18;
const AmountString = `${MinAmount}.00`;

export const WeChatPay = (props) => {

  const [paymentIntent, setPaymentIntent] = useState(null);
  const [userID, setUserID] = useState(null);
  const [response, setResponse] = useState(null);
  const [isProcessing, setProcessing] = useState(false);        
  const [publishableKey, setPublishableKey] = useState('');  
  const [amount, setAmount] = useState(AmountString);
  const [amountError, setAmountError] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [warning, setWarning] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const userID = await getUserId(props);
      setUserID(userID);
    }
    fetchData();
  }, [props]);


  const handleSubmit = async (e) => {
    e.preventDefault();    

    let msgToUser = "";
    const email = props.loggedInUser || localStorage.getItem('existingEmail') || "";    

    // 0) Make sure userID is valid
    if (!Number.isInteger(userID)) {
      msgToUser = `Please log out and in to ensure user ID '${userID}' is valid before paying. 支付前请先登出再登录，确保用户身份有效`;
      setError(msgToUser);
      setSuccess(null);  
      logMessage(msgToUser, email); 
      return;
    }

    // 1) my stripe - upon form is loaded fetch the publishableKey; make sure it is spelled the same as in server

    const { publishableKey } = await fetch(`${_PDP_}/api/config`).then((r) => r.json());
    setPublishableKey(publishableKey);

    if (!publishableKey) {      
      msgToUser = `User ID ${userID}, The server did not return a Stripe publishable key 服务器未返回 Stripe 公钥 for user ID ${userID}`;
      setError(msgToUser);
      setSuccess(null);      
      logMessage(msgToUser, email); 
    }
    // else {
    //   setSuccess("The Stripe publishable API key has been acquired!");
    //   setError(null);
    // }
    const stripe = await loadStripe(publishableKey);

    // 2) 
    if (validateForm()) {
      
      setProcessing(true);

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      };
      
      const data = {
        amount
      };      

      // Make a call to the server to create a new 
      // payment intent and store its clientSecret.
      
      const endpoint = `${_PDP_}/api/create-payment-intent`; 
      const {error: backendError, clientSecret} = await fetch(endpoint, {
          method: 'POST', 
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
              currency: 'cny',
              paymentMethodType: 'wechat_pay',
              amount: amount
          })
        }).then((r)=>r.json())

        if(backendError){
            msgToUser = `User ID ${userID}, create-payment-intent Error 出错: ${backendError.message}`;
            setError(msgToUser);
            setSuccess(null);
            logMessage(msgToUser, email);
            return;
        }

        msgToUser = `User ID ${userID}, Success 成功请等待扫描: create-payment-intent, clientSecret= ${clientSecret}`;
        setSuccess(msgToUser);
        setError(null);
        logMessage(msgToUser, email);

        // Confirm the payment given the clientSecret from the payment intent that
        // was just created on the server.

        const {error: stripeError, paymentIntent} = await stripe.confirmWechatPayPayment(clientSecret, {
          payment_method_options: {
              wechat_pay: {
                  client: 'web'
              }
          }
        });

        if(stripeError) {
            msgToUser = `User ID ${userID}, confirmWechatPayPayment Error 出错: ${stripeError.message}`;
            setError(msgToUser);
            setSuccess(null);
            logMessage(msgToUser, email);
            return;
        }

        // Some time payment received, but still got requires_action causing "Payment Failed"
        // so wait until paymentIntent.status is not "requires_action"
        await new Promise(resolve => setTimeout(resolve, 3000)); // Wait for 3 seconds before checking again        

        if (paymentIntent.status.toLowerCase().includes("succeeded")) {

          msgToUser = `User ID ${userID}, Payment status 支付状态：成功 ${paymentIntent.status} paymentIntentId: ${paymentIntent.id}`;
          setSuccess(msgToUser);
          setError(null);
          logMessage(msgToUser, email);

          // Credit uer account by calling the same success api of US dollars
          const currency = 'cny';
          if (!Number.isInteger(userID)) {
            // Handle the case where userID is not an integer
            msgToUser = `User account not credited yet due to invalid user ID ${userID} 由于用户身份无暂时无法验证，支付尚未入帐。等待客服处理。`;
            setError(msgToUser);
            setSuccess(null);  
            logMessage(msgToUser, email); 
            return;
          }
          creditUserAccount(userID, amount, currency);          

        } else {
          msgToUser = `User ID ${userID}, Payment Failed 支付失败: ${paymentIntent.status} paymentIntentId: ${paymentIntent.id}`;
          setError(msgToUser);
          setSuccess(null);
          logMessage(msgToUser, email);           
        }        

        setProcessing(false);          
    }
  };


  // const handleSubmit = async (e) => {
  //   e.preventDefault();
    
  //   // ... existing code ...
  //   let msgToUser = "";
  //   const email = props.loggedInUser || localStorage.getItem('existingEmail') || "";    

  //   // 0) Make sure userID is valid
  //   if (!Number.isInteger(userID)) {
  //     msgToUser = `Please log out and in to ensure user ID '${userID}' is valid before paying. 支付前请先登出再登录，确保用户身份有效`;
  //     setError(msgToUser);
  //     setSuccess(null);  
  //     setWarning(null);       
  //     logMessage(msgToUser, email); 
  //     return;
  //   }

  //   // 1) my stripe - upon form is loaded fetch the publishableKey; make sure it is spelled the same as in server

  //   const { publishableKey } = await fetch(`${_PDP_}/api/config`).then((r) => r.json());
  //   setPublishableKey(publishableKey);

  //   if (!publishableKey) {      
  //     msgToUser = `User ID ${userID}, The server did not return a Stripe publishable key 服务器未返回 Stripe 公钥 for user ID ${userID}`;
  //     setError(msgToUser);
  //     setSuccess(null);      
  //     setWarning(null);       
  //     logMessage(msgToUser, email); 
  //   }
  //   // else {
  //   //   setSuccess("The Stripe publishable API key has been acquired!");
  //   //   setError(null);
  //   //   setWarning(null);     
  //   // }
  //   const stripe = await loadStripe(publishableKey);

  //   // 2) 

  
  //   if (validateForm()) {
  //     setProcessing(true);
  
  //     // ... existing code ...

  //     setProcessing(true);

  //     const headers = {
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${localStorage.getItem('token')}`,
  //     };
      
  //     const data = {
  //       amount
  //     };      

  //     // Make a call to the server to create a new 
  //     // payment intent and store its clientSecret.
      
  //     const endpoint = `${_PDP_}/api/create-payment-intent`; 
  //     const {error: backendError, clientSecret} = await fetch(endpoint, {
  //         method: 'POST', 
  //         headers: {
  //             'Content-Type': 'application/json'
  //         },
  //         body: JSON.stringify({
  //             currency: 'cny',
  //             paymentMethodType: 'wechat_pay',
  //             amount: amount
  //         })
  //       }).then((r)=>r.json())

  //       if(backendError){
  //           msgToUser = `User ID ${userID}, create-payment-intent Error 出错: ${backendError.message}`;
  //           setError(msgToUser);
  //           setSuccess(null);
  //           setWarning(null);             
  //           logMessage(msgToUser, email);
  //           return;
  //       }

  //       msgToUser = `User ID ${userID}, Success 成功请等待扫描: create-payment-intent, clientSecret= ${clientSecret}`;
  //       setSuccess(msgToUser);
  //       setWarning(null);         
  //       setError(null);
  //       logMessage(msgToUser, email);

  //     // Confirm the payment given the clientSecret from the payment intent that
  //     // was just created on the server.
  //     const { error: stripeError, paymentIntent: initialPaymentIntent } = await stripe.confirmWechatPayPayment(
  //       clientSecret,
  //       {
  //         payment_method_options: {
  //           wechat_pay: {
  //             client: 'web'
  //           }
  //         }
  //       }
  //     );      
  
  //     if (stripeError) {
  //       // ... existing error handling code ...
  //       msgToUser = `User ID ${userID}, confirmWechatPayPayment Error 出错: ${stripeError.message}`;
  //       setError(msgToUser);
  //       setSuccess(null);
  //       setWarning(null);         
  //       logMessage(msgToUser, email);
  //       return;
  //     }

  //     setPaymentIntent(initialPaymentIntent); // Store the initial payment intent      

  //     // Sometime payment received on Stripe, but still got "requires_action" causing "Payment Failed"
  //     // so wait until paymentIntent.status is not "requires_action"

  //     // await alert(paymentIntent.status);
  
  //     // Wait until paymentIntent.status is not "requires_action"
  //     while (paymentIntent.status) { //  === 'requires_action'
  //       // await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 second before checking again
  
  //       const { paymentIntent: updatedPaymentIntent } = await stripe.retrievePaymentIntent(paymentIntent.id);
  //       setPaymentIntent(updatedPaymentIntent); // Update paymentIntent with the new value          

  //       // report status on each repitition
  //       msgToUser = `User ID ${userID}, Payment status 支付状态：${updatedPaymentIntent.status} paymentIntentId: ${updatedPaymentIntent.id}`;
  //       setWarning(msgToUser);        
  //       setSuccess(null);
  //       setError(null);
  //       logMessage(msgToUser, email);

  //       // if (updatedPaymentIntent.status !== paymentIntent.status) {
  //       //   setSuccess(null);
  //       //   setError(null);
  //       //   setWarning(null);           
  
  //       //   if (updatedPaymentIntent.status.toLowerCase().includes('succeeded')) {
  //       //     // Payment succeeded
  //       //     // ... existing success handling code ...
  //       //     msgToUser = `User ID ${userID}, Payment status 支付状态：成功 ${updatedPaymentIntent.status} paymentIntentId: ${updatedPaymentIntent.id}`;
  //       //     setSuccess(msgToUser);
  //       //     setWarning(null);             
  //       //     setError(null);
  //       //     logMessage(msgToUser, email);

  //       //     // Credit uer account by calling the same success api of US dollars
  //       //     const currency = 'cny';
  //       //     if (!Number.isInteger(userID)) {
  //       //       // Handle the case where userID is not an integer
  //       //       msgToUser = `User account not credited yet due to invalid user ID ${userID} 由于用户身份无暂时无法验证，支付尚未入帐。等待客服处理。`;
  //       //       setError(msgToUser);
  //       //       setSuccess(null);  
  //       //       setWarning(null);               
  //       //       logMessage(msgToUser, email); 
  //       //       return;
  //       //     }
  //       //     creditUserAccount(userID, amount, currency);          

  //       //   } else {
  //       //     // Payment failed
  //       //     // ... existing failure handling code ...
  //       //     msgToUser = `User ID ${userID}, Payment Failed 支付失败: ${updatedPaymentIntent.status} paymentIntentId: ${updatedPaymentIntent.id}`;
  //       //     setError(msgToUser);
  //       //     setSuccess(null);
  //       //     setWarning(null);             
  //       //     logMessage(msgToUser, email);
  //       //   }

  //       //   break; // Exit the loop
  //       // }


  //     }
  
  //     setProcessing(false);
  //   }
  // };
  

  const logMessage = async (message, userEmail) => {
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      };

      const endpoint = `${_PDP_}/api/log-message`;          
      const response = await axios.post(endpoint, { message, userEmail }, { headers: headers });
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };    

  const getUserId = async (props) => {
  try {
    const email = props.loggedInUser || localStorage.getItem('existingEmail') || "";
    const endpoint = `${_PDP_}/api/userid?email=${email}`;
    const response = await axios.get(endpoint);
    setUserID(response.data.userID);
    // return here to avoid Object promise of userID
    return parseInt(response.data.userID);
  } catch (error) {
    console.error(error);          
  }
};

const creditUserAccount = async (userID, amount, currency) => {    
    try {
      const endpoint = `${_PDP_}/api/success`; 
      const { data } = await axios.get(endpoint, {
        params: {
          userID,
          amount,
          currency,
        },
      });
      setResponse(data);
      setSuccess(response.message);
      setWarning(null);       
      setError(null);
      // props.setBalance(session.data.balance);
    } catch (error) {
      console.error(error);
      setError(response.message);
      setSuccess(null);
      setWarning(null);       
    }
  };  

  const validateForm = () => {
    let isValid = true;

    if (!amount) {
      setAmountError('Please enter an amount 请输入金额');
      isValid = false;
    } else if (amount < MinAmount) {
        setAmountError(`We kindly inform you that the minimum value for this field is ${MinAmount}. 谨此通知 ${MinAmount} 元起付`);
        isValid = false;  
    } else if (amount > 1000000) {
        setAmountError('Please be advised that the maximum value allowed for this field is 1 million 请注意，最大100万.');
        isValid = false;  
    } else if (!/^[0-9]+(\.[0-9]{1,2})?$/.test(amount)) {
      setAmountError('Kindly input a valid amount that includes a maximum of two decimal places 最多两位小数');
      isValid = false;
    } else {
      setAmountError('');
    }

    return isValid;
  };

  const chineseTextWeChatPay = '开始微信支付';  
  const processingText = '处理中 ...'; 

  return (

    <div className="auth-form-container">
    <h3>WeChat Pay 微信支付</h3>
    {error && <div className="error">{error}</div>}
    {success && <div className="success">{success}</div>}
    {warning && <div className="warning">{warning}</div>}
    {amountError && <div className="error">{amountError}</div>}
    <form className="payment-form" onSubmit={handleSubmit}>

        <label htmlFor="amount">Amount 金额: (CNY &#165; 元)</label>        
        <input
        type="text"
        id="amount"
        value={amount || AmountString} 
        onChange={(event) => setAmount(event.target.value)}
        name="amount"
        pattern="^\d*\.?\d*$"
        min="8"
        required  />
        <button className="auth-form-button wechat-pay" disabled={isProcessing}> {isProcessing ? processingText : chineseTextWeChatPay} </button>

    </form>

    <button className="link-btn" onClick={()=>props.onFormSwitch('login')}>Login 登录</button>    

  </div>
  );
};
