import React, { useState, useEffect, useLayoutEffect } from 'react';
import axios from 'axios';
import { _PDP_ } from './global.js';

import { useContext } from 'react';
// import { AuthContext} from './AuthContext';

export const Login = (props) => {

    // const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);

    // In your functional component:

    const [userIdDescription, setUserIdDescription] = useState('');        
    const [userID, setUserID] = useState('');
    const [token, setToken] = useState('');    
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [warning, setWarning] = useState(null);    
    const [isLoggedIn, setIsLoggedIn] = useState(0);    
    const [isLoggedInServer, setLoggedInServer] = useState(0);                
    const [isReady, setIsReady] = useState(false); // Add a new state variable    

    const saveFormDataToLocalStorage = () => {
      const formData = {
          email,
          pass,
          userID,
          userIdDescription
        };
        localStorage.setItem('loginFormData', JSON.stringify(formData));  
    }
  
    const retrieveFormDataFromLocalStorage = () => {
      const formData = JSON.parse(localStorage.getItem('loginFormData'));
      if (formData) {
        setEmail(formData.email);
        setPass(formData.pass);
        setUserID(formData.userID);
        if (formData.userIdDescription===undefined)
          setUserIdDescription("");
        else
          setUserIdDescription(formData.userIdDescription);
      }
    }

    // retrieve the referral code from the query parameters 
    useEffect(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const referralCode = urlParams.get('referralCode');
      // alert("Login urlParams referralCode= " + referralCode);    
      if (referralCode) {
        localStorage.setItem('referralCode', referralCode);
      }
    }, []);    
  
    useEffect(() => {
      retrieveFormDataFromLocalStorage();
    }, []);    

    // Commented to save some traffic otherwise each question it is called
    // useEffect(() => {
    //   async function fetchData() {
    //     const isLoggedInServer = await getLoggedIn(props);
    //     setLoggedInServer(isLoggedInServer);
    //   }
    //   fetchData();
    // }, [props]);  

    function checkLocalStorage() {
      if (localStorage.getItem('token') !== null) {
        setIsLoggedIn(1);
        setToken(localStorage.getItem('token'));
      } else {
        setIsLoggedIn(0);
      }

      // override with server data
      // setIsLoggedIn(isLoggedInServer)
    }    
    
    // 1)It is possible that the useEffect hook is not being called before the component renders for the first time, which can cause the login status to be displayed incorrectly.
    // To ensure that the useEffect hook is called before the component renders for the first time, you can add a state variable to indicate whether the check has been completed, and use that variable to conditionally render the component.    
    // 2) It's possible that the issue is caused by a race condition between the useEffect hook and the component rendering. To fix this, you can try using the useLayoutEffect hook instead of useEffect. useLayoutEffect runs synchronously after all DOM mutations, which can help avoid race conditions.    
    useLayoutEffect(() => {
      checkLocalStorage();  
      setIsReady(true); // Set the new state variable when the check is completed      
    }, []);
    
    if (!isReady) {
      return null; // Render nothing until the check is completed
    }

    const handleAuthButtonClick = (e) => {
      e.preventDefault();

      console.log(`handleAuthButtonClick called, isLoggedIn= ${isLoggedIn}`);      

      if (isLoggedIn) {
        handleLogout(e);
      } else {
        handleLogin(e);
      }
    };    

    const handleLogout = async (e) => {
      console.log("handleLogout called");
      
      try {
        const response = await fetch(`${_PDP_}/api/logout`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email, pass, token }),
        });

        const data = await response.json();
    
        if (response.ok) {
          localStorage.removeItem('token');          // remove token from local storage
          localStorage.removeItem('existingEmail');  // remove existingEmail from local storage
          setIsLoggedIn(0);
          setWarning(`Attention: ${data.message}`);           
          setSuccess(null);
          setError(null);

          // reset the logged out user
          props.setLoggedInUser(null);
          props.setBalance(null); 

          // do something with the user data, e.g. redirect to home page  
          // history.push('/');
        } else {
          setError(`Logout failed 登出失败: ${data.message}`); 
          setSuccess(null); 
          setWarning(null); 
          // Allow Login again when Logout failed
          localStorage.removeItem('token');          // remove token from local storage          
          setIsLoggedIn(0);
        }


      } catch (error) {
        console.error(error);
        // show error message to user
        setError(error.message);
      }
    };    

    // const history = useHistory();  

    const handleLogin  = async (e) => {
        e.preventDefault();
        console.log(email);

        // Check if email is valid - The overall pattern ensures that the email address has at least one character before the "@" symbol, at least one character between the "@" symbol and the period, and at least one character after the period. It also ensures that there are no spaces anywhere in the email address.
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!regex.test(email)) {
          setError('Please enter a valid email address 请输入有效邮箱地址');
          setSuccess(null);
          setWarning(null);
          return;
        }          
        
        try {
            const response = await fetch(`${_PDP_}/api/login`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ email, pass }),
            });

            const data = await response.json();
        
            if (response.ok) {
              localStorage.setItem('token', data.token); // save token to local storage
              localStorage.setItem('existingEmail', data.existingEmail); // save existingEmail to local storage
              setIsLoggedIn(1);
              setSuccess(`Success 成功: ${data.message}`);
              setError(null);              
              setWarning(null);    

              // Set the logged in user
              props.setLoggedInUser(email);
              props.setBalance(data.balance);   
              props.clearChat();
              props.hideMenu();

              // do something with the user data, e.g. redirect to home page  
              // history.push('/');
            } else {
              setError(`Login failed 登录失败: ${data.message}`); 
              setSuccess(null); 
              setWarning(null);              
            }

            setUserID(`${data.userid}`);
            setUserIdDescription(`（用户号 ${data.userid}）`);

            // this may not have saved userIdDescription
            saveFormDataToLocalStorage();   
            
            // this does
            const formData = {
              email,
              pass,
              userID,
              userIdDescription: `（用户号 ${data.userid}）`
            };
            localStorage.setItem('loginFormData', JSON.stringify(formData));              

          } catch (error) {
            console.error(error);
            // show error message to user
            setError(error.message);
          }

      };    
      
      const getLoggedIn = async (props) => {

        const email = props.loggedInUser || localStorage.getItem('existingEmail') || "";

        try {
          const endpoint = `${_PDP_}/api/userLoggedIn?email=${email}`;
          const response = await axios.get(endpoint);
          setLoggedInServer(response.data.loggedIn);
          // return here to avoid Object promise of userID
          return parseInt(response.data.loggedIn);
        } catch (error) {
          console.error(error);          
        }
      };      
      
    // Render the component when the check is completed      
    return (
        <div className="auth-form-container">

            {/* {isLoggedIn ? 
              <div style={{ marginLeft: '200px'}} className="close-button" onClick={() => props.toggleMenu()}>
                <button title="Close menu 关闭目录">&times;</button>
              </div> : ''
            } */}

            <h3>Login 登录</h3>   
            {error && <div className="error">{error}</div>}
            {success && <div className="success">{success}</div>}
            {warning && <div className="warning">{warning}</div>}             

            <div className={`auth-form-welcome ${isLoggedIn ? 'logout' : 'login'}`} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              {isLoggedIn ? '' : <div style={{textAlign: 'center'}}>Welcome to OpenAI ChatGPT agent!<br/>生而知之™ 智能网欢迎您！</div>}  
            </div> 

            <form className="login-form" onSubmit={handleLogin}>
                <label htmlFor ="email">Email 邮箱:</label>
                <input value={email} onChange={(e) => { setEmail(e.target.value); saveFormDataToLocalStorage(); }} type="email" placeholder="youremail@qq.com" id="email" name="email" required/>

                <label htmlFor ="password">Password 密码:</label>
                <input value={pass} onChange={(e) => { setPass(e.target.value); saveFormDataToLocalStorage(); }} type="password" placeholder="********" id="password" name="password" required/>
                                                                                                                                              
                <button className={`auth-form-button ${isLoggedIn ? 'logout' : 'login'}`} type="button" onClick={handleAuthButtonClick} > {isLoggedIn ? `Logout 登出 ${userIdDescription}` : 'Login 登录'} </button>
            </form>

              {isLoggedIn ? (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <button className="link-btn" onClick={()=>props.onFormSwitch('verifyemail')}>Verify Email 验证邮箱</button> 
                  <button className="support-btn" onClick={()=>props.onFormSwitch('comments')}>Support 客户服务</button>   
                  <button className="payments-btn" onClick={()=>props.onFormSwitch('payments')}>Payments 充值付款</button>
                  <button className="link-btn-wechatgroup" onClick={()=>props.onFormSwitch('wechatgroup')}>WeChat Group 推荐系统</button>

                  {email.includes("georgesjiang@gmail.com") && (
                    <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'column' }}>
                    <button className="HarvardCrimson-link-btn" onClick={()=>props.onFormSwitch('usersviewer')}>Users Viewer 用户一览</button>
                    <button className="HarvardCrimson-link-btn" onClick={()=>props.onFormSwitch('commentsviewer')}>Comments Viewer 评论一览</button>
                    <button className="HarvardCrimson-link-btn" onClick={()=>props.onFormSwitch('questionsviewer')}>Questions Viewer 问题一览</button>
                    <button className="HarvardCrimson-link-btn" onClick={()=>props.onFormSwitch('answersviewer')}>Answers Viewer 答案一览</button>
                    <button className="HarvardCrimson-link-btn" onClick={()=>props.onFormSwitch('officialanswersviewer')}>OfficialAnswers Viewer 官答一览</button>
                    <button className="HarvardCrimson-link-btn" onClick={()=>props.onFormSwitch('paymentsviewer')}>Payments Viewer 支付一览</button>   
                    <button className="HarvardCrimson-link-btn" onClick={()=>props.onFormSwitch('referralsviewer')}>Referrals Viewer 推荐一览</button>
                    <button className="HarvardCrimson-link-btn" onClick={()=>props.onFormSwitch('distributorsviewer')}>Distributors Viewer 分销一览</button>
                    <button className="HarvardCrimson-link-btn" onClick={()=>props.onFormSwitch('websitesviewer')}>Websites Viewer 网站一览</button>
                    <button className="HarvardCrimson-link-btn" onClick={()=>props.onFormSwitch('webscrapingsviewer')}>WebScrapings Viewer 抓丁一览</button>
                    <button className="HarvardCrimson-link-btn" onClick={()=>props.onFormSwitch('statisticsviewer')}>Statistics Viewer 统计一览</button>
                    <button className="HarvardCrimson-link-btn" onClick={()=>props.onFormSwitch('imagesviewer')}>Images Viewer 图像一览</button>                    
                    <button className="HarvardCrimson-link-btn" onClick={()=>props.onFormSwitch('serverlog')}>ServerLog 运行日志</button>
                    </div>
                  )}

                  {(email.includes("georgesjiang@gmail.com") || email.includes("ai@spintrol.com")) && (
                    <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'column' }}>
                    <button className="Spintrol-link-btn" onClick={()=>props.onFormSwitch('spintrol')} ><span className="icon"></span>Spintrol 旋智科技</button>
                    </div>
                  )}

                </div>
              ) : (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <button className="link-btn" onClick={()=>props.onFormSwitch('forgotpassword')}>Forgot Password 忘记密码</button>            
                  <button className="link-btn" onClick={()=>props.onFormSwitch('register')}>Register 注册</button>            
                  <button className="link-btn-wechatgroup" onClick={()=>props.onFormSwitch('wechatgroup')}>WeChat Group 推荐系统</button>                              
                </div>
              )}

        </div>
    );
}
