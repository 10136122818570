import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { _PDP_ } from './global.js';

export const WebScrapingsViewer = (props) => {
    
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [users, setUsers] = useState([]);

  const [editingRow, setEditingRow] = useState(null);  
  const [updatedData, setUpdatedData] = useState(users); // where updatedData is the whole users array

  const [url, setUrl] = useState('https://www.eventbrite.com/e/2023-china-california-business-forum-tickets-607866323537');
  const [website, setWebsite] = useState('https://cis.seu.edu.cn/');  
  const [emails, setEmails] = useState([]);
  const [urls, setUrls] = useState([]);  

  const [newName, setNewName] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [newCountry, setNewCountry] = useState('');
  const [newProvince, setNewProvince] = useState('');
  const [newCompany, setNewCompany] = useState('');
  const [newWebsite, setNewWebsite] = useState('');
  const [newType, setNewType] = useState('');

  const [newPass, setNewPass] = useState('');
  const [newPwd, setNewPwd] = useState('');  
  const [newReferenceId, setReferenceId] = useState('');
  const [newLastSent, setNewLastSent] = useState('');
  const [newSentCount, setNewSentCount] = useState('');
  const [newBalance, setNewBalance] = useState('');
  const [newLoggedIn, setNewLoggedIn] = useState('');
  const [newEmailVerified, setNewEmailVerified] = useState(0);  
  const [newAmount, setNewAmount] = useState(0);  
  const [newCurrency, setNewCurrency] = useState("");    
  const [newSubscribed, setNewSubscribed] = useState("");      

  const setEditingRowById  = id => {
    const row = updatedData.find(row => row.id === id);
    setNewName(row.name);
    setNewEmail(row.email);
    setNewCountry(row.country);
    setNewProvince(row.province);
    setNewCompany(row.company);
    setNewWebsite(row.website);
    setNewType(row.type);  
    setNewPass(row.pass);
    setNewPwd(row.pwd);
    setReferenceId(row.referenceId);
    setNewLastSent(row.lastSent);
    setNewSentCount(row.sentCount);
    setNewBalance(row.balance);
    setNewLoggedIn(row.loggedIn);
    setNewEmailVerified(row.emailVerified);
    setNewAmount(row.amount);        
    setNewCurrency(row.currency);        
    setNewSubscribed(row.subscribed);         

    setEditingRow(id);
  };  

  const handleSaveClick = async (id, newName, newEmail, newCountry, newProvince, newCompany, newWebsite, newType, newPass, newPwd, newReferenceId, newLastSent, newSentCount, newBalance, newLoggedIn, newEmailVerified, newAmount, newCurrency, newSubscribed, props ) => {
    const newData = [...updatedData];
    const index = newData.findIndex(row => row.id === id);
    const oldRow = newData[index];
    const newRow = { ...oldRow, name: newName, email: newEmail, country: newCountry, province: newProvince, company: newCompany, website: newWebsite, type: newType, pass: newPass, pwd: newPwd, referenceId: newReferenceId, lastSent: newLastSent, sentCount: newSentCount, balance: newBalance, loggedIn: newLoggedIn, emailVerified: newEmailVerified, amount: newAmount, currency: newCurrency, subscribed: newSubscribed };    
    if (newRow.name !== oldRow.name || 
      newRow.email !== oldRow.email || 
      newRow.country !== oldRow.country || 
      newRow.province !== oldRow.province || 
      newRow.company !== oldRow.company || 
      newRow.website !== oldRow.website || 
      newRow.type !== oldRow.type ||   
      newRow.pass !== oldRow.pass || 
      newRow.pwd !== oldRow.pwd || 
      newRow.referenceId !== oldRow.referenceId ||                   
      newRow.lastSent !== oldRow.lastSent || 
      newRow.sentCount !== oldRow.sentCount || 
      newRow.balance !== oldRow.balance ||             
      newRow.loggedIn !== oldRow.loggedIn ||                   
      newRow.emailVerified !== oldRow.emailVerified ||                    
      newRow.amount !== oldRow.amount ||
      newRow.currency !== oldRow.currency ||
      newRow.subscribed !== oldRow.subscribed) 
      {
      newData[index] = newRow;
      setUpdatedData(newData);
      setEditingRow(null);

      // where newRow is updated to be pushed to sever

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      };      

      const data =
      {
        id: newRow.id,        
        name: newRow.name,
        email: newRow.email,
        country: newRow.country,
        province: newRow.province,
        company: newRow.company,
        website: newRow.website,
        type: newRow.type,  
        pass: newRow.pass,
        pwd: newRow.pwd,
        referenceId: newRow.referenceId,
        lastSent: newRow.lastSent,
        sentCount: newRow.sentCount,
        balance: newRow.balance,
        loggedIn: newRow.loggedIn,
        emailVerified: newRow.emailVerified,
        amount: newRow.amount,        
        currency: newRow.currency,              
        subscribed: newRow.subscribed        
      };  
      
      try {    
          const endpoint = `${_PDP_}/api/save-row-webscraping`;
          const response = await axios.post(endpoint, data, { headers: headers });
          console.log(response.data);
          setSuccess(response.data.message);
          setError(null);
          props.setBalance(response.data.balance);          

        } catch (error) {
          setError(error.response.data.message);
          setSuccess(null);
        } 
      
      }
  };  

  const renderRow = row => {
    const { id } = row;
    if (editingRow === id) {
      return (
        <tr key={id}>
          <td>{id}</td>
          <td><input className="input2" type="text" value={newName} onChange={e => setNewName(e.target.value)} /></td>
          <td><input className="input2" type="text" value={newEmail} onChange={e => setNewEmail(e.target.value)} /></td>
          <td><input className="input2" type="text" value={newCountry} onChange={e => setNewCountry(e.target.value)} /></td>
          <td><input className="input2" type="text" value={newProvince} onChange={e => setNewProvince(e.target.value)} /></td>
          <td><input className="input2" type="text" value={newCompany} onChange={e => setNewCompany(e.target.value)} /></td>
          <td><input className="input2" type="text" value={newWebsite} onChange={e => setNewWebsite(e.target.value)} /></td>
          <td><input className="input2" type="text" value={newType} onChange={e => setNewType(e.target.value)} /></td>
          <td><input className="input2" type="text" value={newPass} onChange={e => setNewPass(e.target.value)} /></td>
          <td><input className="input2" type="text" value={newPwd} onChange={e => setNewPwd(e.target.value)} /></td>          
          <td><input className="input2" type="number" value={newReferenceId} onChange={e => setReferenceId(e.target.value)} /></td>
          <td><input className="input2" type="text" value={newLastSent} onChange={e => setNewLastSent(e.target.value)} /></td>
          <td><input className="input2" type="number" value={newSentCount} onChange={e => setNewSentCount(e.target.value)} /></td>
          <td><input className="input2" type="number" value={newBalance} onChange={e => setNewBalance(e.target.value)} /></td>
          <td><input className="input2" type="number" value={newLoggedIn} onChange={e => setNewLoggedIn(e.target.value)} /></td>
          <td><input className="input2" type="number" value={newEmailVerified} onChange={e => setNewEmailVerified(e.target.value)} /></td>
          <td><input className="input2" type="text" value={newAmount} onChange={e => setNewAmount(e.target.value)} /></td>                    
          <td><input className="input2" type="text" value={newCurrency} onChange={e => setNewCurrency(e.target.value)} /></td>                            
          <td><input className="input2" type="text" value={newSubscribed} onChange={e => setNewSubscribed(e.target.value)} /></td>                    
          <td>
            <button className="save" onClick={() => handleSaveClick(id, newName, newEmail, newCountry, newProvince, newCompany, newWebsite, newType, newPass, newPwd, newReferenceId, newLastSent, newSentCount, newBalance, newLoggedIn, newEmailVerified, newAmount, newCurrency, newSubscribed, props)}>Save</button>
            <button className="cancel" onClick={() => setEditingRow(null)}>Cancel</button>            
          </td>

        </tr>
      );
    } else {
      return (
        <tr key={id}>
          <td>{row.id}</td>
          <td>{row.name}</td>          
          <td>{row.email}</td>
          <td>{row.country}</td>
          <td>{row.province}</td>
          <td>{row.company}</td>
          <td>{row.website}</td>
          <td>{row.type}</td>
          <td>{"***"}</td>          
          <td>{"XXX"}</td>
          <td>{row.referenceId}</td>          
          <td>{row.lastSent}</td>
          <td>{row.sentCount}</td>
          <td>{row.balance}</td>
          <td>{row.loggedIn}</td>
          <td>{row.emailVerified}</td>   
          <td>{row.amount}</td>                              
          <td>{row.currency}</td>                                      
          <td>{row.subscribed}</td>                              
          <td>
            <button className="edit" onClick={() => setEditingRowById(id)}>Edit</button>            
          </td>
        </tr>
      );
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);


  const fetchUsers = async () => {

    const endpoint = `${_PDP_}/api/webscrapings` ;
    const response = await fetch (endpoint, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem('token')}`, // include token in header        
      }
    });    

    if (response.ok) {
      const data = await response.json();      
      console.log(data.message);
      setSuccess(data.message);        
      setError(null);        
      setUsers(data);  
      setUpdatedData(data);      
    } else {
      // handle error
      const msgToUser = `Please log in to get the required authentication. Access Status: ${response.status} - ${response.statusText}.`;      
      console.log(msgToUser);
      setSuccess(null);        
      setError(msgToUser);        
    }        
  };        

  const handleSearchUrl = async () => {
    try {
      const endpoint = `${_PDP_}/api/search-url`;
      const response = await axios.post(endpoint, { url });
      setEmails(response.data);
    } catch (error) {
      console.error('Error occurred while searching and extracting emails:', error);
    }
  };

  const handleSearchWebsite = async () => {
    try {
      const endpoint = `${_PDP_}/api/search-website`;
      const response = await axios.post(endpoint, { website });
      setUrls(response.data);
    } catch (error) {
      console.error('Error occurred while searching and extracting emails:', error);
    }
  };

  const webScrape = async () => {
    try {
      const endpoint = `${_PDP_}/api/scrape`;
      const response = await axios.post(endpoint, { website });
      setEmails(response.data);
    } catch (error) {
      console.error('Error occurred while searching and extracting emails:', error);
    }
  };  

  const refreshUsers = () => {
    fetchUsers();
  }  
  
  const stopBroadcast = async () => {
    try {
      const endpoint = `${_PDP_}/api/stop-broadcast`;
      const response = await axios.get(endpoint);
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };  

  const sendBroadcastEmails = async () => {
    try {
      const endpoint = `${_PDP_}/api/send-broadcast-emails`;      
      const response = await axios.get(endpoint);
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };  

  return (
    <div className="auth-form-container">
      <h3>WebScrapings Viewer</h3>   
      {error && <div className="error">{error}</div>}
      {success && <div className="success">{success}</div>}

      <div className="table-container">
        <table className="table-form">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Email</th>
              <th>Country</th>
              <th>Province</th>
              <th>Company</th>
              <th>Website</th>
              <th>Type</th>
              <th>Pass</th>
              <th>Pwd</th>    
              <th>RefID</th>                                
              <th>LastSent</th>
              <th>SentCount</th>
              <th>Balance</th>
              <th>LoggedIn</th>
              <th>EmailVerified</th>
              <th>Amount</th>            
              <th>Currency</th>                    
              <th>Subscribed</th>            
            </tr>
          </thead>
          <tbody>
            {updatedData.map(renderRow)}
          </tbody>
        </table>
      </div>
      <div style={{ marginTop: '20px' }}>                      
        <button className="link-btn" onClick={stopBroadcast}>Stop Broadcast</button>
        <button className="link-btn" onClick={sendBroadcastEmails}>Broadcast Emails</button>      
        <button className="link-btn" onClick={webScrape}>Web Scrape</button>
        <button className="link-btn" onClick={refreshUsers}>Refresh</button>
        <button className="link-btn" onClick={()=>props.onFormSwitch('login')}>Login</button>
      </div>

      <div>
        <div>
          <label htmlFor="urlInput">URL: </label>
          <input type="text" id="urlInput" value={url} onChange={(e) => setUrl(e.target.value)} style={{ width: "600px" }} />
          <button onClick={handleSearchUrl}>Search Emails</button>
        </div>

        <div>
          <label htmlFor="websiteInput">Website: </label>
          <input type="text" id="websiteInput" value={website} onChange={(e) => setWebsite(e.target.value)} style={{ width: "600px" }} />
          <button onClick={handleSearchWebsite}>Search Emails</button>
        </div>

        <ul style={{ textAlign: 'left' }}>
          {emails.map((email, index) => (
            <li key={email}>{`${index + 1}. ${email}`}</li>
          ))}
        </ul>

        <ul style={{ textAlign: 'left' }}>
          {urls.map((url, index) => (
            <li key={url}>{`${index + 1}. ${url}`}</li>
          ))}
        </ul>                

      </div>

    </div>
    );  
}
