import React, { useState } from 'react';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { _PDP_ } from './global.js';
import { getFormattedDate } from './dateUtils';

export const ServerLog = (props) => {

  const [comment, setComment] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

// To download one file
//   const handleDownload = async () => {
//     try {
//       alert('handleDownload clicked');        
//       const endpoint = `${_PDP_}/api/download-log`;    
//       const response = await axios.get(endpoint, { responseType: 'blob' });
//       console.log(response.data);
//       const url = window.URL.createObjectURL(new Blob([response.data]));
//       const link = document.createElement('a');
//       link.href = url;
//       link.setAttribute('download', 'log.txt');
//       document.body.appendChild(link);
//       link.click();
//     } catch (error) {
//       console.error(error);
//       setError(error.response.data.message);
//     }
//   };

// In this code, we use axios to make a GET request to the /api/downloadlog endpoint on the server. We set the responseType to blob, which tells axios to treat the response as a binary blob.
// Once we receive the response, we create a new Blob object with the response data and set the file type to 'application/zip'. We then use the file-saver package to download the file with the name 'server-logs.zip'.

  const handleDownload = async () => {
    try {

    //   alert('handleDownload clicked');        
      const endpoint = `${_PDP_}/api/download-log`;    
      const response = await axios.get(endpoint, { responseType: 'blob' });
  
      const file = new Blob([response.data], { type: 'application/zip' });
      saveAs(file, 'server-logs.zip');

      setSuccess("The Serverlog has been downloaded successfully!");
      setError(null);
    } catch (error) {
      console.error(error);
      setSuccess(null);
      setError(`Download error: ${error.message}`);
    }
  }   


  const handleSubmit = async (e) => {
    e.preventDefault();

    // const date = new Date();
    // const timestamp = date.toLocaleString();
    const timestamp = getFormattedDate(1);

    // Submit comment to server with timestamp      
    const messages = `[${timestamp}] ${comment}`; // 'Hello World'
    const currentModel = 'some model'; // not used
    
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    };
    
    const data = {
      message: messages,
      currentModel: currentModel,
    };

    try {    
        const endpoint = `${_PDP_}/api/viewlog`;
        const response = await axios.post(endpoint, data, { headers: headers });
        console.log(response.data);
        // setSuccess(response.data.message);
        setError(null);
        setSuccess("The view log operation was successful");  
        setComment(response.data.message);
      } catch (error) {
        setError(error.response.data.message);
        setSuccess(null);
        setComment("");        
      }  
  };  

  return (
    <div className="auth-form-container">
      <h3>Server Log</h3>
      {error && <div className="error">{error}</div>}
      {success && <div className="success">{success}</div>}
      <form className="forgot-form" onSubmit={handleSubmit}>
        <label htmlFor ="comment">
          <textarea
            id="Comments"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder=""
            style={{ height: '400px', width: '800px', padding: '10px', marginTop: '1em', borderRadius: '10px' }}            
          />
        </label>
        <div>
          <button className="auth-form-button viewlog" type="submit" style={{ width: '150px' }}>View Log</button>
          <button className="auth-form-button downloadlog" onClick={handleDownload} type="button" style={{ width: '150px', marginLeft: '10px' }}>Download Log</button>          
        </div>
      </form>
      <button className="link-btn" onClick={()=>props.onFormSwitch('login')}>Login</button>
    </div>
  );
}





