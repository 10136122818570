import React, { useState, useEffect, useRef } from "react";
import QRCode from 'qrcode';
import axios from 'axios';
// import wx from 'weixin-js-sdk';

// import {useSelector} from "react-redux";

// import {url} from "../slices/api";
import usdtQRCode from "./images/sheng_wechat_group_qr_code.png";
import { _PDP_ } from './global.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWeixin } from '@fortawesome/free-brands-svg-icons'

export const WeChatGroup = (props) => {
   
  const [refRate, setRefRate] = useState("30");      
  const [userID, setUserID] = useState(null);
  const [referralLink, setReferralLink] = useState('');  
  const [copied3, setCopied3] = useState(false);  // copied3 meaning downloaded
  const [copied2, setCopied2] = useState(false);
  const [copied, setCopied] = useState(false);  
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [warning, setwarning] = useState(null);      

  const websiteDescription = `美国人工智能研究实验室 OpenAI 合作伙伴, 大西洋技术有限公司（加拿大）最新推出生而知之™ 网，可以在国内使用ChatGPT，以共享世界最新科技成果。网站底层直接调用OpenAI的API，与世界同步。具有ChatGPT聊天和DALL.E绘画变图视频生成功能。涵盖最新人工智能模型，包括SORA。免费注册体验！欢迎使用并推广。新用户注册时可输入推荐人号码（即您的用户号）。推广方法：点击“生成链接并复制”，“生成二维码贴图并下载”；生成的链接或二维码含有您的用户号。转发人自动成为推荐人，获得 ${refRate}% 收益！孔子曰：生而知之者，上也!`;    

  // const websiteDescription = (
  //   <p>
  //     加拿大大西洋技术有限公司东南大学校友开发的网站，网址为{' '}
  //     <a href="https://xplayonline.com/" target="_blank" rel="noopener noreferrer" style={{ color: 'magenta' }}>
  //       https://xplayonline.com/
  //     </a>
  //     。该网站可以在国内使用ChatGPT，以共享世界最新科技成果。网站底层直接调用OpenAI的API，与世界同步。网站涵盖50个人工智能模型和温度水平选项，包括许多编程模型。注册后，您可以免费获得一些体验题目。欢迎使用并将其分享给您的微信群和朋友圈。为确保安全使用，请在注册时输入推荐人号码（即您的用户号）。推荐人获得 20% 收益！
  //   </p>
  // );    
  

  useEffect(() => {
    async function fetchData() {
      const userID = await getUserId(props);
      setUserID(userID);
    }
    fetchData();
  }, [props]);

  const logMessage = async (message, userEmail) => {
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      };

      const endpoint = `${_PDP_}/api/log-message`;
      const response = await axios.post(endpoint, { message, userEmail }, { headers: headers });
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };  

  const getUserId = async (props) => {
    try {
      const email = props.loggedInUser || localStorage.getItem('existingEmail') || "";
      const endpoint = `${_PDP_}/api/userid?email=${email}`;
      const response = await axios.get(endpoint);
      setUserID(response.data.userID);
      // return here to avoid Object promise of userID
      return parseInt(response.data.userID);
    } catch (error) {
      console.error(error);          
    }
  };  

  const generateReferralLink = () => {

    const email = props.loggedInUser || localStorage.getItem('existingEmail') || "";    

    // 0) Make sure userID is valid
    if (!Number.isInteger(userID)) {
      const msgToUser = `Please log out and in to ensure user ID '${userID}' is valid before referral. 推荐前请先登出再登录，确保用户号码正确`;
      setError(msgToUser);
      setSuccess(null);  
      setwarning(null);
      logMessage(msgToUser, email); 
      return;
    }

    const endpoint = `${_PDP_}/api/generate-referral-link?email=${email}`;
    axios.get(endpoint)
      .then(res => {
        setReferralLink(res.data.referralLink);
        handleCopy2(res.data.referralLink);
        // generateQRCode(res.data.referralLink);
        generateQRCode2(res.data.referralLink);
      })
      .catch(err => console.log(err));
  }
    
  const handleCopy2 = (textToCopy) => {
    navigator.clipboard.writeText(textToCopy); // 'Your copied text here' in noticeboard
    setCopied2(true);
    setTimeout(() => setCopied2(false), 3000); // Reset the "Copied" status after 3 seconds
  };  

  const handleCopy = () => {
    navigator.clipboard.writeText(websiteDescription); // 'Your copied text here' in noticeboard
    setCopied(true);
    setTimeout(() => setCopied(false), 3000); // Reset the "Copied" status after 3 seconds
  };  

  // const shareToWeChat = () => {
  //   const shareLink = referralLink; //'https://example.com'; // Replace with your desired link to share

  //   alert("shareLink= " + shareLink);
  
  //   // Configure the WeChat SDK with your app ID and required API list
  //   wx.config({
  //     appId: 'YOUR_APP_ID',
  //     timestamp: 'TIMESTAMP',
  //     nonceStr: 'NONCE_STR',
  //     signature: 'SIGNATURE',
  //     jsApiList: ['onMenuShareTimeline'],
  //   });
  
  //   // Set up the sharing content and trigger the sharing action
  //   wx.ready(() => {
  //     wx.onMenuShareTimeline({
  //       title: '一个网站可以在中国使用ChatGPT的安全可靠网站',
  //       link: shareLink,
  //       imgUrl: 'URL_TO_IMAGE',
  //       success: () => {
  //         const msgToUser = 'Shared successfully to WeChat friend circles 微信朋友圈分享成功';
  //         console.log();
  //         setSuccess(msgToUser);
  //         setError(null);
  //         setwarning(null);
  //         logMessage(msgToUser, props.loggedInUser); 
  //       },
  //       cancel: () => {
  //         const msgToUser = 'Sharing canceled 分享已取消';
  //         console.log(msgToUser);
  //         setSuccess(null);
  //         setError(null);
  //         setwarning(msgToUser);
  //         logMessage(msgToUser, props.loggedInUser); 
  //       },
  //     });
  //   });
  // };  

  const [qrCode, setQRCode] = useState('');
  const qrCodeRef = useRef(null);
  const [qrGenerated, setQrGenerated] = useState(false);  
  const [combinedImage, setCombinedImage] = useState('');

  const generateCombinedImage = async () => {

    if (referralLink === null || referralLink === "") {
      return;
    }    

    try {
      const endpoint = `${_PDP_}/api/combinedimage?text=${referralLink}`;      
      const response = await axios.get(endpoint);
      setCombinedImage(response.data);
      handleDownload(response.data);
    } catch (error) {      
      console.error('Error generating combined image:', error);
      setError(`Error generating combined image:${error.message}`)
      setSuccess(null);
      setwarning(null);
    }
  };

  const handleDownload = (href) => {
    const link = document.createElement('a');
    link.href = href;
    link.download = `app_user_${userID}_qr_code.png`;//'combined-image.png'; // Specify the filename
    link.click();

    setCopied3(true);
    setTimeout(() => setCopied3(false), 5000); // Reset the "Copied" status after 5 seconds for cell phone to screenshot
  };

  const generateQRCode2 = async (referralLink) => {
    try {
      const canvas = qrCodeRef.current;
      const qrData = referralLink;//'https://example.com'; // Example QR code data

      const size = 100; // Specify the desired size in pixels

      await QRCode.toCanvas(canvas, qrData, { width: size, height: size });      
      setQrGenerated(true); 
      
      handleSaveImage();      
    } catch (error) {
      console.error('Error generating QR code:', error);
    }
  };

  const handleSaveImage = () => {
    const canvas = qrCodeRef.current;
    const link = document.createElement('a');

    try {
      // Get the data URL of the canvas as a JPEG image
      const dataUrl = canvas.toDataURL('image/png');

      // Set the anchor element's attributes
      link.href = dataUrl;
      link.download = `user_${userID}_qr_code.png`; // Specify the filename            

      // Programmatically trigger a click event to prompt the user to save the file
      link.click();
    } catch (error) {
      console.error('Error saving QR code image:', error);
    }
  };

  const generateQRCode = async (text) => {    
    try {
      const endpoint = `${_PDP_}/api/generateQRCode?text=${text}`;
      const response = await axios.get(endpoint);
      setQRCode(response.data.qrCode);
    } catch (error) {
      console.error(error);
    }
  };

  return (

    <div className="auth-form-container">
    <h3>WeChat Group 推荐系统</h3>
    {error && <div className="error">{error}</div>}
    {success && <div className="success">{success}</div>}
    {warning && <div className="warning">{warning}</div>}     
    <button className="link-btn" onClick={()=>props.onFormSwitch('login')}>Login 登录</button>    

    <div style={{ maxWidth: "300px", margin: "0 auto" }}>
      <div style={{ marginTop: "1rem" }}>
        <h2 style={{color: 'green'}}><FontAwesomeIcon icon={faWeixin} /> 扫码进群!</h2> 

        <div style={{ marginBottom: '10px', color: 'white', fontSize: '12px' }}>
            本群交流 OpenAI合作伙伴 生而知之™ 智能网的使用方法
        </div>

        <img src={usdtQRCode} alt="WeChat QR Code" style={{ maxWidth: "100%", marginBottom: '1px' }} />
      </div>

      <div className="wechat-sale-container">

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <button className="auth-subform-button" onClick={generateReferralLink}>{copied2 ? 'Copied' : 'Copy'} {copied2 ? '已复制，再次点击下载二维码' : 'QR Code 生成链接并复制'}</button>
          {/* <button onClick={shareToWeChat}>Share to WeChat 发朋友圈</button> */}
        </div>

        {referralLink && <p>{referralLink}</p>}

        <div>
          {qrGenerated && <canvas ref={qrCodeRef} />}          
        </div>

        <div>
          {referralLink && <button className="auth-subform-button" onClick={generateCombinedImage}>生成二维码贴图并下载</button>}
          {combinedImage && copied3 === true && (
            <div>
              <img src={combinedImage} alt="Combined Image" />
              {/* <button className="auth-subform-button" onClick={handleDownload(combinedImage)}>下载二维码贴图</button> */}
            </div>
          )}
        </div>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h1 className="wechat-sale-title">转发参考文本</h1>
          <button className="auth-subform-button" type="submit" style={{ width: '130px', marginTop: '20px', marginLeft: '10px' }} onClick={handleCopy}>
            {copied ? 'Copied' : 'Copy'} {copied ? '已复制' : '复制文本'}
          </button>
        </div>

        <p>{websiteDescription}</p>

      </div>      

    </div>    

  </div>
  );
};




