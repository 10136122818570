import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { _PDP_ } from './global.js';

export const ReferralsViewer = (props) => {
    
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [questions, setQuestions] = useState([]);

  const [editingRow, setEditingRow] = useState(null);  
  const [updatedData, setUpdatedData] = useState(questions); // where updatedData is the whole users array

  const [newUserid, setNewUserid] = useState('');
  const [newTime, setNewTime] = useState('');
  const [newRefCount, setNewRefCount] = useState('');
  const [newRegCount, setNewRegCount] = useState('');      
  const [newRegistered, setNewRegistered] = useState("");      

  const setEditingRowById  = id => {
    const row = updatedData.find(row => row.id === id);
    setNewUserid(row.userid);
    setNewTime(row.time);
    setNewRefCount(row.refcount);    
    setNewRegCount(row.regcount);        
    setNewRegistered(row.registered);

    setEditingRow(id);
  };  

  const handleSaveClick = async (id, newUserid, newTime, newRefCount, newRegCount, newRegistered, props ) => {
    const newData = [...updatedData];
    const index = newData.findIndex(row => row.id === id);
    const oldRow = newData[index];
    const newRow = { ...oldRow, userid: newUserid, time: newTime, refcount: newRefCount, regcount: newRegCount, registered: newRegistered };    
    if (
      newRow.userid !== oldRow.userid || 
      newRow.time !== oldRow.time || 
      newRow.refcount !== oldRow.refcount ||
      newRow.regcount !== oldRow.regcount ||      
      newRow.registered !== oldRow.registered) 
      {
      newData[index] = newRow;
      setUpdatedData(newData);
      setEditingRow(null);

      // where newRow is updated to be pushed to sever

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      };      

      const data =
      {
        id: newRow.id,        
        userid: newRow.userid,
        time: newRow.time,
        refcount: newRow.refcount,
        regcount: newRow.regcount,        
        registered: newRow.registered        
      };  
      
      try {    
          const endpoint = `${_PDP_}/api/save-row-referral`;
          const response = await axios.post(endpoint, data, { headers: headers });
          console.log(response.data);
          setSuccess(response.data.message);
          setError(null);
          props.setBalance(response.data.balance);          

        } catch (error) {
          setError(error.response.data.message);
          setSuccess(null);
        } 
      
      }
  };  

  const renderRow = row => {
    const { id } = row;
    if (editingRow === id) {
      return (
        <tr key={id}>
          <td>{id}</td>          
          <td><input className="input2" type="number" value={newUserid} onChange={e => setNewUserid(e.target.value)} /></td>
          <td><input className="input2" type="text" value={newTime} onChange={e => setNewTime(e.target.value)} /></td> 
          <td><input className="input2" type="number" value={newRefCount} onChange={e => setNewRefCount(e.target.value)} /></td> 
          <td><input className="input2" type="number" value={newRegCount} onChange={e => setNewRegCount(e.target.value)} /></td> 
          <td><input className="input2" type="number" value={newRegistered} onChange={e => setNewRegistered(e.target.value)} /></td>
          <td>
            <button className="save" onClick={() => handleSaveClick(id, newUserid, newTime, newRefCount, newRegCount, newRegistered, props)}>Save</button>
            <button className="cancel" onClick={() => setEditingRow(null)}>Cancel</button>            
          </td>

        </tr>
      );
    } else {
      return (
        <tr key={id}>
          <td>{row.id}</td>
          <td>{row.userid}</td>
          <td>{row.time}</td>
          <td>{row.refcount}</td>
          <td>{row.regcount}</td>                    
          <td>{row.registered}</td>
          <td>
            <button className="edit" onClick={() => setEditingRowById(id)}>Edit</button>            
          </td>
        </tr>
      );
    }
  };
  
  useEffect(() => {
    fetchRecords();
  }, []);


  const fetchRecords = async () => {

    const endpoint = `${_PDP_}/api/referrals` ;
    const response = await fetch (endpoint, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem('token')}`, // include token in header        
      }
    });    

    if (response.ok) {
      const data = await response.json();      
      console.log(data.message);
      setSuccess(data.message);        
      setError(null);        
      setQuestions(data);  
      setUpdatedData(data);      
    } else {
      // handle error
      const msgToUser = `Please log in to get the required authentication. Access Status: ${response.status} - ${response.statusText}.`;      
      console.log(msgToUser);
      setSuccess(null);        
      setError(msgToUser);        
    }        
  };      

  const refreshRecords = () => {
    fetchRecords();
  }

  return (
    <div className="auth-form-container">
    <h3>Referrals Viewer</h3>   
    {error && <div className="error">{error}</div>}
    {success && <div className="success">{success}</div>}

    <div className="table-container">
      <table className="table-form">
        <thead>
          <tr>
            <th>ID</th>
            <th>Userid</th>
            <th>Time</th>
            <th>RefCount</th>
            <th>RegCount</th>
            <th>Registered</th>
          </tr>
        </thead>
        <tbody>
          {updatedData.map(renderRow)}
        </tbody>
      </table>
    </div>
    <div style={{ marginTop: '20px' }}>
      <button className="link-btn" onClick={refreshRecords}>Refresh</button>                 
      <button className="link-btn" onClick={()=>props.onFormSwitch('login')}>Login</button>             
    </div>
    </div>
    );  
}