import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { _PDP_ } from './global.js';

export const UsersViewer = (props) => {
    
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [users, setUsers] = useState([]);

  const [editingRow, setEditingRow] = useState(null);  
  const [updatedData, setUpdatedData] = useState(users); // where updatedData is the whole users array

  const [newName, setNewName] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [newPass, setNewPass] = useState('');
  const [newPwd, setNewPwd] = useState('');  
  const [newReferenceId, setReferenceId] = useState('');
  const [newLastLogin, setNewLastLogin] = useState('');
  const [newLoginCount, setNewLoginCount] = useState('');
  const [newBalance, setNewBalance] = useState('');
  const [newLoggedIn, setNewLoggedIn] = useState('');
  const [newEmailVerified, setNewEmailVerified] = useState(0);  
  const [newAmount, setNewAmount] = useState(0);  
  const [newCurrency, setNewCurrency] = useState("");    
  const [newSubscribed, setNewSubscribed] = useState("");      

  // const handleEditClick = id => {
  //   setEditingRow(id);
  // };    

  const setEditingRowById  = id => {
    const row = updatedData.find(row => row.id === id);
    setNewName(row.name);
    setNewEmail(row.email);
    setNewPass(row.pass);
    setNewPwd(row.pwd);
    setReferenceId(row.referenceId);
    setNewLastLogin(row.lastLogin);
    setNewLoginCount(row.loginCount);
    setNewBalance(row.balance);
    setNewLoggedIn(row.loggedIn);
    setNewEmailVerified(row.emailVerified);
    setNewAmount(row.amount);        
    setNewCurrency(row.currency);        
    setNewSubscribed(row.subscribed);         

    setEditingRow(id);
  };  

  const handleSaveClick = async (id, newName, newEmail, newPass, newPwd, newReferenceId, newLastLogin, newLoginCount, newBalance, newLoggedIn, newEmailVerified, newAmount, newCurrency, newSubscribed, props ) => {
    const newData = [...updatedData];
    const index = newData.findIndex(row => row.id === id);
    const oldRow = newData[index];
    const newRow = { ...oldRow, name: newName, email: newEmail, pass: newPass, pwd: newPwd, referenceId: newReferenceId, lastLogin: newLastLogin, loginCount: newLoginCount, balance: newBalance, loggedIn: newLoggedIn, emailVerified: newEmailVerified, amount: newAmount, currency: newCurrency, subscribed: newSubscribed };    
    if (newRow.name !== oldRow.name || 
      newRow.email !== oldRow.email || 
      newRow.pass !== oldRow.pass || 
      newRow.pwd !== oldRow.pwd || 
      newRow.referenceId !== oldRow.referenceId ||                   
      newRow.lastLogin !== oldRow.lastLogin || 
      newRow.loginCount !== oldRow.loginCount || 
      newRow.balance !== oldRow.balance ||             
      newRow.loggedIn !== oldRow.loggedIn ||                   
      newRow.emailVerified !== oldRow.emailVerified ||                    
      newRow.amount !== oldRow.amount ||
      newRow.currency !== oldRow.currency ||
      newRow.subscribed !== oldRow.subscribed) 
      {
      newData[index] = newRow;
      setUpdatedData(newData);
      setEditingRow(null);

      // where newRow is updated to be pushed to sever

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      };      

      const data =
      {
        id: newRow.id,        
        name: newRow.name,
        email: newRow.email,
        pass: newRow.pass,
        pwd: newRow.pwd,
        referenceId: newRow.referenceId,
        lastLogin: newRow.lastLogin,
        loginCount: newRow.loginCount,
        balance: newRow.balance,
        loggedIn: newRow.loggedIn,
        emailVerified: newRow.emailVerified,
        amount: newRow.amount,        
        currency: newRow.currency,              
        subscribed: newRow.subscribed        
      };  
      
      try {    
          const endpoint = `${_PDP_}/api/save-row-user`;
          const response = await axios.post(endpoint, data, { headers: headers });
          console.log(response.data);
          setSuccess(response.data.message);
          setError(null);
          props.setBalance(response.data.balance);          

        } catch (error) {
          setError(error.response.data.message);
          setSuccess(null);
        } 
      
      }
  };  

  const renderRow = row => {
    const { id } = row;
    if (editingRow === id) {
      return (
        <tr key={id}>
          <td>{id}</td>
          <td><input className="input2" type="text" value={newName} onChange={e => setNewName(e.target.value)} /></td>
          <td><input className="input2" type="text" value={newEmail} onChange={e => setNewEmail(e.target.value)} /></td>
          <td><input className="input2" type="text" value={newPass} onChange={e => setNewPass(e.target.value)} /></td>
          <td><input className="input2" type="text" value={newPwd} onChange={e => setNewPwd(e.target.value)} /></td>          
          <td><input className="input2" type="number" value={newReferenceId} onChange={e => setReferenceId(e.target.value)} /></td>
          <td><input className="input2" type="text" value={newLastLogin} onChange={e => setNewLastLogin(e.target.value)} /></td>
          <td><input className="input2" type="number" value={newLoginCount} onChange={e => setNewLoginCount(e.target.value)} /></td>
          <td><input className="input2" type="number" value={newBalance} onChange={e => setNewBalance(e.target.value)} /></td>
          <td><input className="input2" type="number" value={newLoggedIn} onChange={e => setNewLoggedIn(e.target.value)} /></td>
          <td><input className="input2" type="number" value={newEmailVerified} onChange={e => setNewEmailVerified(e.target.value)} /></td>
          <td><input className="input2" type="text" value={newAmount} onChange={e => setNewAmount(e.target.value)} /></td>                    
          <td><input className="input2" type="text" value={newCurrency} onChange={e => setNewCurrency(e.target.value)} /></td>                            
          <td><input className="input2" type="text" value={newSubscribed} onChange={e => setNewSubscribed(e.target.value)} /></td>                    
          <td>
            <button className="save" onClick={() => handleSaveClick(id, newName, newEmail, newPass, newPwd, newReferenceId, newLastLogin, newLoginCount, newBalance, newLoggedIn, newEmailVerified, newAmount, newCurrency, newSubscribed, props)}>Save</button>
            <button className="cancel" onClick={() => setEditingRow(null)}>Cancel</button>            
          </td>

        </tr>
      );
    } else {
      return (
        <tr key={id}>
          <td>{row.id}</td>
          <td>{row.name}</td>          
          <td>{row.email}</td>
          <td>{"***"}</td>          
          <td>{"XXX"}</td>
          <td>{row.referenceId}</td>          
          <td>{row.lastLogin}</td>
          <td>{row.loginCount}</td>
          <td>{row.balance}</td>
          <td>{row.loggedIn}</td>
          <td>{row.emailVerified}</td>   
          <td>{row.amount}</td>                              
          <td>{row.currency}</td>                                      
          <td>{row.subscribed}</td>                              
          <td>
            <button className="edit" onClick={() => setEditingRowById(id)}>Edit</button>            
          </td>
        </tr>
      );
    }
  };


  useEffect(() => {
    fetchUsers();
  }, []);


  const fetchUsers = async () => {

    const endpoint = `${_PDP_}/api/users` ;
    const response = await fetch (endpoint, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem('token')}`, // include token in header        
      }
    });    

    if (response.ok) {
      const data = await response.json();      
      console.log(data.message);
      setSuccess(data.message);        
      setError(null);        
      setUsers(data);  
      setUpdatedData(data);      
    } else {
      // handle error
      const msgToUser = `Please log in to get the required authentication. Access Status: ${response.status} - ${response.statusText}.`;      
      console.log(msgToUser);
      setSuccess(null);        
      setError(msgToUser);        
    }        
  };      

  const refreshUsers = () => {
    fetchUsers();
  }

  return (
    <div className="auth-form-container">
      <h3>Users Viewer</h3>   
      {error && <div className="error">{error}</div>}
      {success && <div className="success">{success}</div>}

      <div className="table-container">
        <table className="table-form">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Email</th>
              <th>Pass</th>
              <th>Pwd</th>    
              <th>RefID</th>                                
              <th>LastLogin</th>
              <th>LoginCount</th>
              <th>Balance</th>
              <th>LoggedIn</th>
              <th>EmailVerified</th>
              <th>Amount</th>            
              <th>Currency</th>                    
              <th>Subscribed</th>            
            </tr>
          </thead>
          <tbody>
            {updatedData.map(renderRow)}
          </tbody>
        </table>
      </div>
      <div style={{ marginTop: '20px' }}>    
        <button className="link-btn" onClick={refreshUsers}>Refresh</button>                 
        <button className="link-btn" onClick={()=>props.onFormSwitch('login')}>Login</button>             
      </div>
    </div>
    );  
}