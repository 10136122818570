import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { _PDP_ } from './global.js';

export const UserManualsViewer = (props) => {

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [warning, setWarning] = useState(null);    
  const [usermanuals, setUsermanuals] = useState([]);

  const [selectedRow, setSelectedRow] = useState(null);  
  const [editingRow, setEditingRow] = useState(null);  
  const [updatedData, setUpdatedData] = useState(usermanuals); // where updatedData is the whole users array

  const [newName, setNewName] = useState('');
  const [newProduct, setNewProduct] = useState('');
  const [newType, setNewType] = useState('');
  const [newTime, setNewTime] = useState('');  
  const [newStatus, setNewStatus] = useState('');
  const [newDownloads, setNewDownloads] = useState('');  

  const handleRowClick = (id) => {
    setSelectedRow(id);
    const row = usermanuals.find(row => row.id === id);
    setNewName(row.name);

    // alert(`id= ${id}, newName= ${row.name}`);    

    setWarning(null);
    setSuccess(null);
    setError(null);
  };

  const setEditingRowById  = id => {
    const row = updatedData.find(row => row.id === id);
    setNewName(row.name);
    setNewProduct(row.product);
    setNewType(row.type);
    setNewTime(row.time);
    setNewStatus(row.status);
    setNewDownloads(row.downloads);

    setEditingRow(id);
  };  

  const handleSaveClick = async (id, newName, newProduct, newType, newTime, newStatus, newDownloads, props ) => {
    const newData = [...updatedData];
    const index = newData.findIndex(row => row.id === id);
    const oldRow = newData[index];
    const newRow = { ...oldRow, name: newName, product: newProduct, type: newType, time: newTime, status: newStatus, downloads: newDownloads };    
    if (newRow.name !== oldRow.name || 
      newRow.product !== oldRow.product || 
      newRow.type !== oldRow.type || 
      newRow.time !== oldRow.time || 
      newRow.status !== oldRow.status ||       
      newRow.downloads !== oldRow.downloads) 
      {
      newData[index] = newRow;
      setUpdatedData(newData);
      setEditingRow(null);

      // where newRow is updated to be pushed to sever

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      };      

      const data =
      {
        id: newRow.id,        
        name: newRow.name,
        product: newRow.product,
        type: newRow.type,
        time: newRow.time,        
        status: newRow.status,
        downloads: newRow.downloads
      };  
      
      try {    
          const endpoint = `${_PDP_}/api/save-row-usermanual`;
          const response = await axios.post(endpoint, data, { headers: headers });
          console.log(response.data);
          setSuccess(response.data.message);
          setError(null);
          setWarning(null);
          props.setBalance(response.data.balance);          

        } catch (error) {
          setError(error.response.data.message);
          setSuccess(null);
          setWarning(null);
        } 
      
      }
  };  

  const renderRow = row => {
    const { id } = row;
    if (editingRow === id) {
      return (
        <tr key={id}>
          <td>{id}</td>
          <td><input className="input2" type="text" value={newName} onChange={e => setNewName(e.target.value)} /></td>
          <td><input className="input2" type="text" value={newProduct} onChange={e => setNewProduct(e.target.value)} /></td>
          <td><input className="input2" type="text" value={newType} onChange={e => setNewType(e.target.value)} /></td>
          <td><input className="input2" type="text" value={newTime} onChange={e => setNewTime(e.target.value)} /></td>          
          <td><input className="input2" type="text" value={newStatus} onChange={e => setNewStatus(e.target.value)} /></td>
          <td><input className="input2" type="number" value={newDownloads} onChange={e => setNewDownloads(e.target.value)} /></td>
          <td>
            <button className="save" onClick={() => handleSaveClick(id, newName, newProduct, newType, newTime, newStatus, newDownloads, props)}>Save</button>
            <button className="cancel" onClick={() => {setEditingRow(null);setSuccess(null);setError(null);setWarning(null);}}>Cancel</button> 
          </td>

        </tr>
      );
    } else {
      return (
        <tr key={id} onClick={() => handleRowClick(row.id)}>
          <td>{row.id}</td>
          <td>{row.name}</td>          
          <td>{row.product}</td>
          <td>{row.type}</td>
          <td>{row.time}</td>
          <td>{row.status}</td>
          <td>{row.downloads}</td>          
          <td>
            <button className="edit" onClick={() => setEditingRowById(id)}>Edit</button>
            <button className="download-button" onChange={e => setNewName(e.target.value)} onClick={() => handleDownload()}>Download</button>            
          </td>
        </tr>
      );
    }
  };

  useEffect(() => {
    fetchUsermanuals();
  }, []);


  const fetchUsermanuals = async () => {

    const endpoint = `${_PDP_}/api/usermanuals` ;
    const response = await fetch (endpoint, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem('token')}`, // include token in header        
      }
    });    

    if (response.ok) {
      const data = await response.json();      
      console.log(data.message);
      setSuccess(data.message);        
      setError(null);        
      setWarning(null);
      setUsermanuals(data);  
      setUpdatedData(data);      
    } else {
      // handle error
      const msgToUser = `Please log in to get the required authentication. Access Status: ${response.status} - ${response.statusText}.`;      
      console.log(msgToUser);
      setSuccess(null);              
      setError(msgToUser);        
      setWarning(null);
    }        
  };      

  const refreshUsermanuals = () => {
    fetchUsermanuals();
  }

  const handleDownload = () => {
    const filename = newName;//"SPC1168_Datasheet.pdf";

    if (!filename) {
      setWarning(`Attention: The filename is empty: '${filename}'. Please select a file by clicking on the 'Edit' button and then 'Cancel'`);
      setSuccess(null);
      setError(null);
      return;
    } else {
      setWarning(null);
      setSuccess(null);
      setError(null);
    }

    // Encode each segment of the file path separately to handle Chinese file names
    const segments = filename.split('/');
    const encodedSegments = segments.map(segment => encodeURIComponent(segment));
    const encodedFilename = encodedSegments.join('/');    

    // In the client-side code, you would use encodeURI to encode the file name: in case of Chinese
    const endpoint = `${_PDP_}/api/download-usermanual/${encodedFilename}`;
    axios({
      url: endpoint, // replace filename with the actual filename
      method: 'GET',
      responseType: 'blob', // set the response type to blob
    }).then((response) => {
      // Create a temporary URL for the blob and download it
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename); // replace filename with the actual filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setWarning(null);
      setSuccess(`The file '${filename}' has been successfully downloaded!`);
      setError(null);

    }).catch((error) => {
      setWarning(null);
      setSuccess(null);
      setError(error.message);
      console.log(error);
    });     

    refreshUsermanuals();    
  };  

  return (
    <div className="auth-form-container">
      <h3>User Manuals Viewer 用户手册一览</h3>   
      {error && <div className="error">{error}</div>}
      {success && <div className="success">{success}</div>}
      {warning && <div className="warning">{warning}</div>} 
      <div className="table-container">
        <table className="table-form">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Product</th>
              <th>Type</th>
              <th>Time</th>            
              <th>Status</th>
              <th>Downloads</th>
            </tr>
          </thead>
          <tbody>
            {updatedData.map(renderRow)}
          </tbody>
        </table>
      </div>

      <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'column' }}>
        <button className="link-btn" onClick={refreshUsermanuals}>Refresh 刷新</button>
        <button className="link-btn" onClick={()=>props.onFormSwitch('spintrol')}><span className="icon"></span>Spintrol 旋智科技</button>
      </div>

      {/* {(email.includes("georgesjiang@gmail.com") || email.includes("ai@spintrol.com")) && (
        <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'column' }}>
        <button className="link-btn" onClick={refreshUsers}>Refresh</button>
        <button className="Spintrol-link-btn" onClick={()=>props.onFormSwitch('spintrol')}><span className="icon"></span>Spintrol 旋智科技</button>
        </div>
      )} */}

    </div>
    );  
}


