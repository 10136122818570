import React, { useState } from 'react';
import axios from 'axios';
import { _PDP_ } from './global.js';


export const ForgotPassword = (props) => {

  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleForgotPassword = async (e) => {
    e.preventDefault();

    try {      
      const response = await axios.post(`${_PDP_}/api/forgot-password`, { email });
      setSuccess(response.data.message);
      setError(null);
    } catch (error) {
      setError(error.response.data.message);
      setSuccess(null);
    }
  };

  return (
    <div className="auth-form-container">
      <h3>Forgot Password 忘记密码</h3>
      {error && <div className="error">{error}</div>}
      {success && <div className="success">{success}</div>}
      <form className="forgot-form" onSubmit={handleForgotPassword}>
        <label htmlFor ="email">Email 邮箱:</label>
        <input value={email} onChange={(e)=>setEmail(e.target.value)} type="email" placeholder="youremail@qq.com" id="email" name="email" required/>
        <button className="auth-form-button forgot" type="submit">Request Token 请求验证码</button>
      </form>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <button className="link-btn" onClick={()=>props.onFormSwitch('resetpassword')}>Reset Password 重设密码</button>      
        <button className="link-btn" onClick={()=>props.onFormSwitch('login')}>Login 登录</button>      
      </div>
    </div>
  );
}
