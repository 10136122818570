import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { _PDP_ } from './global.js';
import { getFormattedDate } from './dateUtils';

export const Comments = (props) => {

  const [videos, setVideos] = useState([]);
  const [copied, setCopied] = useState(false);  
  const [userId, setUserId] = useState("");      
  const [loggedInUser, setLoggedInUser] = useState("");    
  const [isSubscribed, setIsSubscribed] = useState(false);  
  const [noticeboard, setNoticeboard] = useState('');  
  const [comment, setComment] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if the comment is empty or only contains white space
    if (!comment.trim()) {
        setError('Comment or feedback cannot be empty 评论反馈不能为空白');
        setSuccess(null);        
        return;
    }    

    const timestamp = getFormattedDate(1);

    // Submit comment to server with timestamp      
    const messages = `[${timestamp}] ${comment}`; // 'Hello World'
    const currentModel = 'some model'; // not used
    
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    };
    
    const data = {
      message: messages,
      currentModel: currentModel,
    };

    try {    
        const endpoint = `${_PDP_}/api/comments`;
        const response = await axios.post(endpoint, data, { headers: headers });
        console.log(response.data);
        setSuccess(response.data.message);
        setError(null);
        setComment('');        
      } catch (error) {
        setError(error.response.data.message);
        setSuccess(null);
      } 

      refreshNotice(); 
  };  

  const handleCheckboxChange = (e) => {
    setIsSubscribed(e.target.checked);

    setLoggedInUser(props.loggedInUser);
    subscribe(!isSubscribed, props.loggedInUser);
    fetchSubscribe();
  };      

  const subscribe = async (isSubscribed, userEmail) => {
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      };

      const endpoint = `${_PDP_}/api/subscribe`;
      const response = await axios.post(endpoint, {isSubscribed, userEmail}, { headers: headers });
      console.log(response.data);

      if (response.data.success) {
         setUserId(response.data.userId)
      } else {
      }
  
    } catch (error) {
      console.error(error);
    }
  };  

  useEffect(() => {
    fetchNotice();
    fetchSubscribe();
  }, []);


  useEffect(() => {
    const endpoint = `${_PDP_}/api/videos` ;
    axios.get(endpoint)
      .then(response => {
        setVideos(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);  

  const fetchSubscribe = async () => {

    const endpoint = `${_PDP_}/api/fetchsubscribe` ;
    const response = await fetch (endpoint, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem('token')}`, // include token in header        
      }
    });    

    if (response.ok) {
      const data = await response.json();      
      console.log(data.message);
      setSuccess(data.message);        
      setError(null);        
      setIsSubscribed(data.subscribe);  
    } else {
      // handle error
      const msgToUser = `Please log in to get the required authentication. 请登录 Access Status: ${response.status} - ${response.statusText}.`;      
      console.log(msgToUser);
      setSuccess(null);        
      setError(msgToUser);        
    }        
  };      

  const fetchNotice = async () => {

    const endpoint = `${_PDP_}/api/notice` ;
    const response = await fetch (endpoint, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem('token')}`, // include token in header        
      }
    });    

    if (response.ok) {
      const data = await response.json();      
      console.log(data.message);
      setSuccess(data.message);        
      setError(null);        
      setNoticeboard(data.notice);  
    } else {
      // handle error
      const msgToUser = `Please log in to get the required authentication. 请登录 Access Status: ${response.status} - ${response.statusText}.`;      
      console.log(msgToUser);
      setSuccess(null);        
      setError(msgToUser);        
    }        
  };      

  const refreshNotice = () => {
    fetchNotice();
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(noticeboard); // 'Your copied text here' in noticeboard
    setCopied(true);
    setTimeout(() => setCopied(false), 3000); // Reset the "Copied" status after 3 seconds
  };  

  return (
    <div className="auth-form-container" >
      <h3>Support 客服与交流</h3>
      <button className="link-btn" onClick={()=>props.onFormSwitch('login')}>Login 登录</button>      

      {error && <div className="error">{error}</div>}
      {success && <div className="success">{success}</div>}
      <form className="forgot-form" onSubmit={handleSubmit}>
        <label htmlFor ="comment">Comments and feedback 评论反馈与交流: <textarea id="Comments" value={comment} onChange={(e) => setComment(e.target.value)} placeholder="Type your comments or feedback here&#10;在此评论反馈与交流"
        style={{ height: '30px', width: '100%', padding: '10px', marginTop: '1em', borderRadius: '10px', fontSize: '12px'}} 
        />  </label>        
        <button className="auth-form-button comment" type="submit">Submit 提交</button>
      </form>
      
      <div>
        <label className="auth-subform-button" htmlFor="subscriptionCheckbox">
          <input type="checkbox" id="subscriptionCheckbox" name="subscriptionCheckbox" checked={isSubscribed} onChange={handleCheckboxChange} style={{ width: '10px', marginLeft: '0px' }}
          /> {isSubscribed ? 'Subscribed' : 'Subscribe'} {isSubscribed ? '已订阅' : '订阅免费特别服务'}
        </label>

        <button className="auth-subform-button" type="submit" style={{ width: '120px', marginLeft: '10px' }} onClick={handleCopy}>
        {copied ? 'Copied' : 'Copy'} {copied ? '已复制' : '复制'}
        </button>
        <button className="auth-subform-button" type="submit" style={{ width: '130px', marginLeft: '10px' }} disabled={!isSubscribed || noticeboard===''}>Download PDF 下载</button>
        <button className="auth-subform-button" type="submit" style={{ width: '110px', marginLeft: '10px' }} disabled={!isSubscribed || noticeboard===''}>Email PDF 发邮件</button>            
      </div>

      <label htmlFor ="noticeboard">Free Special Service 免费特别服务: <textarea id="noticeboard" value={noticeboard} onChange={(e) => setNoticeboard(e.target.value)} placeholder="Special Notice&#10;特别信息" 
      style={{ height: '200px', width: '100%',  padding: '10px', marginTop: '1em', borderRadius: '10px', fontSize: '12px', userSelect: 'text' }} readOnly 
        />
      </label>

      <div>
        <h4>Music Video Player</h4>
        <ul>
          {videos.map(video => (
            <li key={video.id}>
              <h3>{video.title}</h3>
              <video src={video.url} controls />
            </li>
          ))}
        </ul>
      </div>

    </div>
  );

}


