export const MY_GLOBAL_VARIABLE = 'myValue';

const global_protocol = 'http';
const global_domain = 'localhost';
const global_port = '9113';

const s_string = '';

const global_DEBUG = false; // does not matter true or false because _PDP_ is hard-coded to ""

let _PDP_ = global_DEBUG
  ? `${global_protocol}${s_string}://${global_domain}:${global_port}`
  : `https://xplayonline.com`;  


  // Set up a proxy in your React app by adding a "proxy" key in your package.json file:
  // "proxy": "http://localhost:9113", 
  // With this configuration, any API calls made from your React app using a relative path (e.g. "/api/config") will be automatically redirected to the Node.js server running on localhost:9113.
  _PDP_   = "";

export { _PDP_ };
