import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { _PDP_ } from './global.js';
import { ElementsConsumer } from '@stripe/react-stripe-js';

export const Register = (props) => {    

    const [disclaimerChecked, setDisclaimerChecked] = useState(false);
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');        
    const [name, setName] = useState('');       
    const [referenceId, setReferenceId] = useState(localStorage.getItem('referralCode') !== null ? localStorage.getItem('referralCode') : '');
    const [warning, setwarning] = useState(null);    
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [confirmPass, setConfirmPass] = useState('');

    const saveFormDataToLocalStorage = () => {
      const formData = {
        email,
        pass,
        name,
        referenceId: Number(referenceId), // convert to number before storing
        confirmPass
      };
      localStorage.setItem('registerFormData', JSON.stringify(formData));
    //   alert("saveFormDataToLocalStorage referenceId.toString()= " + referenceId.toString());
      localStorage.setItem('referralCode', referenceId.toString());
    }      
    
    const retrieveFormDataFromLocalStorage = () => {
        const formData = JSON.parse(localStorage.getItem('registerFormData'));
        if (formData) {
          setEmail(formData.email);
          setPass(formData.pass);
          setName(formData.name);
          setReferenceId(Number(formData.referenceId)); // parse as number
          if (localStorage.getItem('referralCode') !==null)
            setReferenceId(Number(localStorage.getItem('referralCode')));
          else
            getReferralId();
          setConfirmPass(formData.confirmPass);
        }
    }      

    // retrieve the referral code from the query parameters - very good! - each time referralCode is always from urlParams although user can change
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const referralCode = urlParams.get('referralCode');
        // alert("Register urlParams referralCode= " + referralCode);
        if (referralCode) {
          localStorage.setItem('referralCode', referralCode);
        } else {
          getReferralId();
        }
      }, []);          
    
    useEffect(() => {
        retrieveFormDataFromLocalStorage();
        if (localStorage.getItem('referralCode') !==null)
            setReferenceId(Number(localStorage.getItem('referralCode')));
    }, []);    


    const getReferralId = async (props) => {
        try {
          const endpoint = `${_PDP_}/api/referralid`;
          const response = await axios.get(endpoint);
        //   alert("getReferralId response.data.userID= " + response.data.latestUserId);
          if (response.data.latestUserId === undefined)
            setReferenceId("");
          else
            setReferenceId(parseInt(response.data.latestUserId));
          // return here to avoid Object promise of userID
          return parseInt(response.data.latestUserId);
        } catch (error) {
          console.error(error);          
        }
      };          

    const handleSubmit = async (e) => {
        e.preventDefault();

        console.log(email);

        // alert("referralCode= " + localStorage.getItem('referralCode'));

        if (!disclaimerChecked) {
            setSuccess(null);                
            setError(null);                            
            setwarning("Please read and accept the disclaimer before registering 请在注册前阅读并同意免责声明");
        } else {
            // your existing form submission code

            if (referenceId < 0) {
                setError('Referral ID cannot be negative 推荐人号码不能为负');
                setSuccess(null);                
                setwarning(null);
                return;
            }

            /* tested cde but Disabled - annoying
            let confirmed = true;
            if (referenceId === "") {
              confirmed = window.confirm("No Referral ID? 您确定没有推荐人吗？");
            } else {
              confirmed = window.confirm(`Are you sure the Referral ID ${referenceId} is correct? 您确定推荐人号码 ${referenceId} 正确吗？`);
            }            
            if (!confirmed) {
              // do something
              setError('Fill in the correct Referral ID 填写准确的推荐人号码');
              setSuccess(null);                
              setwarning(null);
              return;
            } 
            */           

            // Check if email is valid - The overall pattern ensures that the email address has at least one character before the "@" symbol, at least one character between the "@" symbol and the period, and at least one character after the period. It also ensures that there are no spaces anywhere in the email address.
            const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!regex.test(email)) {
            setError('Please enter a valid email address 请输入有效的邮箱地址');
            setSuccess(null);
            setwarning(null);            
            return;
            }    

            if (pass !== confirmPass) {
                setError('Passwords do not match 密码不一致');
                setSuccess(null);                
                setwarning(null);                            
                return;
            }
        
            try {
                const response = await fetch(`${_PDP_}/api/register`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ name, email, pass, referenceId: parseInt(referenceId) }),
                });

                console.log(response);          
                const data = await response.json();        

                if (response.ok) {
                    setSuccess(`Success 成功: ${data.message}`); 
                    setError(null);        
                    setwarning(null);
                    props.setBalance(data.balance);            
                } else {
                    setError(`Registration failed 注册失败: ${data.message}`);
                    setSuccess(null);
                    setwarning(null);                    
                }
            
            } catch (error) {
                console.error(error);
                setError('An unexpected error occurred.');
                setSuccess(null);                
                setwarning(null);                
            }
        }
    };      

    const handleCheckboxChange = (e) => {
        setDisclaimerChecked(e.target.checked);
      };      

    const passwordTitle = "密码 8 个字符以上含大写字母和数字 Password must be at least 8 characters long and contain at least one uppercase letter and one number";
    return (
        <div className="auth-form-container">
            <h3>Register 注册</h3>
            {error && <div className="error">{error}</div>}
            {success && <div className="success">{success}</div>}
            {warning && <div className="warning">{warning}</div>} 
            <form className="register-form" onSubmit={handleSubmit}>

                <label htmlFor="name">Username 用户名:（任意）</label>
                <input value={name} onChange={(e) => { setName(e.target.value); saveFormDataToLocalStorage(); }} type="name" placeholder="username" id="name" name="name" required/>
                
                <label htmlFor="number">Optional Referral ID 可选推荐人号码:</label>
                <input value={referenceId} onChange={(e) => { setReferenceId(e.target.value); saveFormDataToLocalStorage(); }} type="number" placeholder="" id="number" name="number"/>

                {/* <div className="form-row" style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <div className="form-group col-md-6">
                        <div className="input-wrapper">
                        <label htmlFor="name" style={{marginLeft: '-18px'}}>Username 用户名:（任意）</label>
                        <input value={name} onChange={(e) => { setName(e.target.value); saveFormDataToLocalStorage(); }} type="name" placeholder="username" id="name" name="name" required/>
                        </div>
                    </div>
                    <div className="form-group col-md-6">
                        <div className="input-wrapper">
                        <label htmlFor="number">Ref. ID 推荐人号码:（可以不填）</label>
                        <input value={referenceId} onChange={(e) => { setReferenceId(e.target.value); saveFormDataToLocalStorage(); }} type="number" placeholder="" id="number" name="number"/>
                        </div>
                    </div>
                </div> */}

                <label htmlFor ="email">Email 邮箱:</label>
                <input value={email} onChange={(e) => { setEmail(e.target.value); saveFormDataToLocalStorage(); }} type="email" placeholder="youremail@qq.com" id="email" name="email" required/> 

                <label htmlFor ="password">Password 密码: （8 个字符以上含大写字母和数字）</label>
                <input value={pass} onChange={(e) => { setPass(e.target.value); saveFormDataToLocalStorage(); }} type="password" placeholder="********" id="password" name="password" pattern="(?=.*\d)(?=.*[A-Z]).{8,}" title={passwordTitle}  required/>                                    

                <label htmlFor ="confirmPassword">Confirm Password 确认密码: （>=8 chars with uppercase and num）</label>
                <input value={confirmPass} onChange={(e) => { setConfirmPass(e.target.value); saveFormDataToLocalStorage(); }} type="password" placeholder="********" id="password" name="password" pattern="(?=.*\d)(?=.*[A-Z]).{8,}" title={passwordTitle} required/>                                                           

                <label className="auth-subform-button" htmlFor="disclaimerCheckbox">
                   <input type="checkbox" id="disclaimerCheckbox" name="disclaimerCheckbox" checked={disclaimerChecked} onChange={handleCheckboxChange} /> 同意免责声明 I have read and agree to the terms and conditions 
                </label>
                <button className="disclaimer-link-btn" onClick={()=>props.onFormSwitch('disclaimer')}>Disclaimer 免责声明</button>                

                <button className="auth-form-button register" type="submit">Register 注册</button>
            </form>                        

            <button className="link-btn" onClick={()=>props.onFormSwitch('login')}>Login 登录</button>
        </div>
    )
}

