import React, { useState } from 'react';
import axios from 'axios';
import { _PDP_ } from './global.js';

export const VerifyEmail = (props) => {

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(null); 

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(email);

    // Check if email is valid - The overall pattern ensures that the email address has at least one character before the "@" symbol, at least one character between the "@" symbol and the period, and at least one character after the period. It also ensures that there are no spaces anywhere in the email address.
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!regex.test(email)) {
      setError('Please enter a valid email address 请输入有效的邮箱地址');
      setSuccess(null);
      return;
    }      
    
    try {
      const response = await axios.post(`${_PDP_}/api/verify-email` , { email });

      if (response.data.success) {
        setSuccess(response.data.message);
        setError(null);  
      } else {
        setError(response.data.message);
        setSuccess(null);        
      }

    } catch (error) {
      setError(error.response.data.message);
      setSuccess(null);
    }
  };

  return (
    <div className="auth-form-container">
        <h3>Verify Email 验证邮箱</h3>
        {error && <div className="error">{error}</div>}
        {success && <div className="success">{success}</div>}
        <form className="verify-form" onSubmit={handleSubmit}>
            <label htmlFor ="email">Email 邮箱: </label>
            <input value={email} onChange={(e)=>setEmail(e.target.value)} type="email" placeholder="youremail@qq.com" id="email" name="email" required/>
            <button className="auth-form-button verifyemail" type="submit">Verify Email 发送验证邮件</button>
        </form>
        <button className="link-btn" onClick={()=>props.onFormSwitch('login')}>Login 登录</button>             
    </div>
  );
}
