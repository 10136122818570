import logo from './logo.svg';
import './normal.css';
import './App.css';
import './index.css';

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import GraphemeSplitter from 'grapheme-splitter';

import shengChineseCode from "./images/24px-生-seal.svg_inverted_left.png";
import shengChineseCode1 from "./images/24px-生-seal.svg_inverted_up.png";
import shengChineseCode2 from "./images/24px-生-seal.svg_inverted_left.png";
import shengChineseCode3 from "./images/24px-生-seal.svg_inverted_down.png";
import shengChineseCode4 from "./images/24px-生-seal.svg_inverted_right.png";
import kongfuzi from "./images/KongZi3.png";


import { _PDP_ } from './global.js';
import { Login } from './Login';
import { Register } from './Register';
import { ForgotPassword } from './ForgotPassword';
import { ResetPassword } from './ResetPassword';
import { VerifyEmail } from './VerifyEmail';
import { Comments } from './Comments';
import { Spintrol } from './Spintrol';
import { Disclaimer } from './Disclaimer';
import { UsersViewer } from './UsersViewer';
import { WebScrapingsViewer } from './WebScrapingsViewer';
import { UserManualsViewer } from './UserManualsViewer';
import { QuestionsViewer } from './QuestionsViewer';
import { ReferralsViewer } from './ReferralsViewer';
import { WebsitesViewer } from './WebsitesViewer';
import { DistributorsViewer } from './DistributorsViewer';
import { StatisticsViewer } from './StatisticsViewer';
import { PaymentsViewer } from './PaymentsViewer';
import { CommentsViewer } from './CommentsViewer';
import { AnswersViewer } from './AnswersViewer';
import { ImagesViewer } from './ImagesViewer';
import { OfficialAnswersViewer } from './OfficialAnswersViewer';
import { ServerLog } from './ServerLog';
import { Payments } from './Payments';
import { WeChatGroup } from './WeChatGroup';
import { WeChatPay } from './WeChatPay';
import { AliPay } from './AliPay';
import { ApplePay } from './ApplePay';
import { Advertisement } from './Advertisement';
// import { AuthContext } from './AuthContext';

// import { Elements } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js';

import styled, { keyframes } from 'styled-components';

const blink = keyframes`
  50% {
    opacity: 0;
  }
`;

const Heading = styled.h4`
  color: ${props => props.color || 'black'};
  
  span {
    color: blue;
    animation: ${blink} 10s infinite;
  }
`;

const defaultFunctions = [
  {
    object: 'engine',
    id: 'ChatGPT 文本问答',
    max_tokens: 20,
    ready: true,
    owner: 'openai',
    permissions: null,
    created: null
  },
  {
    object: 'engine',
    id: 'DALL.E Create Image 绘画',
    max_tokens: 20,
    ready: true,
    owner: 'openai',
    permissions: null,
    created: null
  },
  {
    object: 'engine',
    id: 'DALL.E Image Variation 变图',
    max_tokens: 20,
    ready: true,
    owner: 'openai',
    permissions: null,
    created: null
  }  
];



const defaultSizes = [
  {
    object: 'engine',
    id: '1024x1024 高',
    max_tokens: 20,
    ready: true,
    owner: 'openai',
    permissions: null,
    created: null
  },
  {
    object: 'engine',
    id: '512x512 中',
    max_tokens: 20,
    ready: true,
    owner: 'openai',
    permissions: null,
    created: null
  },
  {
    object: 'engine',
    id: '256x256 低',
    max_tokens: 20,
    ready: true,
    owner: 'openai',
    permissions: null,
    created: null
  }  
];

const defaultModels = [
  {
    object: 'engine',
    id: 'gpt-4',
    max_tokens: 200,
    ready: true,
    owner: 'openai',
    permissions: null,
    created: null
  },
  {
    object: 'engine',
    id: 'babbage-002',
    max_tokens: 20,
    ready: true,
    owner: 'openai',
    permissions: null,
    created: null
  },
  {
    object: 'engine',
    id: 'gpt-4-0613',
    max_tokens: 20,
    ready: true,
    owner: 'openai',
    permissions: null,
    created: null
  },
  {
    object: 'engine',
    id: 'gpt-4-32k-0613',
    max_tokens: 20,
    ready: true,
    owner: 'openai',
    permissions: null,
    created: null
  },
  {
    object: 'engine',
    id: 'babbage-code-search-code',
    max_tokens: 20,
    ready: true,
    owner: 'openai-dev',
    permissions: null,
    created: null
  },
  {
    object: 'engine',
    id: 'babbage-code-search-text',
    max_tokens: 20,
    ready: true,
    owner: 'openai-dev',
    permissions: null,
    created: null
  },
  {
    object: 'engine',
    id: 'babbage-similarity',
    max_tokens: 20,
    ready: true,
    owner: 'openai-dev',
    permissions: null,
    created: null
  },
  {
    object: 'engine',
    id: 'whisper-1',
    max_tokens: 20,
    ready: true,
    owner: 'openai-internal',
    permissions: null,
    created: null
  },
  {
    object: 'engine',
    id: 'gpt-3.5-turbo-instruct',
    max_tokens: 2000,
    ready: true,
    owner: 'openai',
    permissions: null,
    created: null
  },
  {
    object: 'engine',
    id: 'text-embedding-ada-002',
    max_tokens: 20,
    ready: true,
    owner: 'openai-dev',
    permissions: null,
    created: null
  },
  {
    object: 'engine',
    id: 'curie-instruct-beta',
    max_tokens: 20,
    ready: true,
    owner: 'openai',
    permissions: null,
    created: null
  },
  {
    object: 'engine',
    id: 'ada-code-search-code',
    max_tokens: 20,
    ready: true,
    owner: 'openai-dev',
    permissions: null,
    created: null
  },
  {
    object: 'engine',
    id: 'ada-similarity',
    max_tokens: 20,
    ready: true,
    owner: 'openai-dev',
    permissions: null,
    created: null
  },
  {
    object: 'engine',
    id: 'gpt-3.5-turbo',
    max_tokens: 20,
    ready: true,
    owner: 'openai',
    permissions: null,
    created: null
  },
  {
    object: 'engine',
    id: 'davinci-search-document',
    max_tokens: 20,
    ready: true,
    owner: 'openai-dev',
    permissions: null,
    created: null
  },
  {
    object: 'engine',
    id: 'gpt-3.5-turbo-0613',
    max_tokens: 20,
    ready: true,
    owner: 'openai',
    permissions: null,
    created: null
  },
  {
    object: 'engine',
    id: 'ada-code-search-text',
    max_tokens: 20,
    ready: true,
    owner: 'openai-dev',
    permissions: null,
    created: null
  },
  {
    object: 'engine',
    id: 'davinci-instruct-beta',
    max_tokens: 20,
    ready: true,
    owner: 'openai',
    permissions: null,
    created: null
  },
  {
    object: 'engine',
    id: 'ada-search-query',
    max_tokens: 20,
    ready: true,
    owner: 'openai-dev',
    permissions: null,
    created: null
  },
  {
    object: 'engine',
    id: 'text-search-davinci-query-001',
    max_tokens: 20,
    ready: true,
    owner: 'openai-dev',
    permissions: null,
    created: null
  },
  {
    object: 'engine',
    id: 'curie-search-query',
    max_tokens: 20,
    ready: true,
    owner: 'openai-dev',
    permissions: null,
    created: null
  },
  {
    object: 'engine',
    id: 'davinci-search-query',
    max_tokens: 20,
    ready: true,
    owner: 'openai-dev',
    permissions: null,
    created: null
  },
  {
    object: 'engine',
    id: 'babbage-search-document',
    max_tokens: 20,
    ready: true,
    owner: 'openai-dev',
    permissions: null,
    created: null
  },
  {
    object: 'engine',
    id: 'ada-search-document',
    max_tokens: 20,
    ready: true,
    owner: 'openai-dev',
    permissions: null,
    created: null
  },
  {
    object: 'engine',
    id: 'curie-search-document',
    max_tokens: 20,
    ready: true,
    owner: 'openai-dev',
    permissions: null,
    created: null
  },
  {
    object: 'engine',
    id: 'babbage-search-query',
    max_tokens: 20,
    ready: true,
    owner: 'openai-dev',
    permissions: null,
    created: null
  },
  {
    object: 'engine',
    id: 'curie-similarity',
    max_tokens: 20,
    ready: true,
    owner: 'openai-dev',
    permissions: null,
    created: null
  },
  {
    object: 'engine',
    id: 'davinci-002',
    max_tokens: 20,
    ready: true,
    owner: 'openai',
    permissions: null,
    created: null
  },
  {
    object: 'engine',
    id: 'davinci-similarity',
    max_tokens: 20,
    ready: true,
    owner: 'openai-dev',
    permissions: null,
    created: null
  }
];

const defaultTemperatures = [
  {
    id: '0.1',
    name: 'Temperature 1',
    description: 'This is the second model',
  },
  {
    id: '0.2',
    name: 'Temperature 2',
    description: 'This is the second model',
  },
  {
    id: '0.3',
    name: 'Temperature 3',
    description: 'This is the second model',
  },
  {
    id: '0.4',
    name: 'Temperature 4',
    description: 'This is the second model',
  },
  {
    id: '0.5',
    name: 'Temperature 5',
    description: 'This is the second model',
  },
  {
    id: '0.6',
    name: 'Temperature 6',
    description: 'This is the second model',
  },
  {
    id: '0.7',
    name: 'Temperature 7',
    description: 'This is the second model',
  },
  {
    id: '0.8',
    name: 'Temperature 8',
    description: 'This is the second model',
  },
  {
    id: '0.9',
    name: 'Temperature 9',
    description: 'This is the second model',
  },
  {
    id: '1.0',
    name: 'Temperature 10',
    description: 'This is the third model',
  },
];  

function App() {

  const fileInputRef = useRef(null);  
  const [file, setFile] = useState('');

  // const [displayImage, setDisplayImage] = useState(false);
  const [imageURL, setImageURL] = useState('');
  
  //Shark will show: const [appBrand, setAppBrand] = useState(appName + " " + version);      
  // const [isLoggedIn, setIsLoggedIn] = useState(false);  
  const [stockPrice, setStockPrice] = useState('');
  const [changePercent, setChangePercent] = useState('');
  const [marketStatus, setMarketStatus] = useState('');
  const [stock, setStock] = useState("MSFT: 287.50 -1.30 (-0.45%)");    

  const [appBrand, setAppBrand] = useState("");    
  const [loggedInUser, setLoggedInUser] = useState("");  
  const [balance, setBalance] = useState("");  
  const [currentForm, setCurrentForm] = useState("login");

  // add state for input and chat log  

  const [questionid, setQuestionid] = useState('');    
  const [question, setQuestion] = useState('');
  const [showMenu, setShowMenu] = useState(true); // initialize state variable to true
  const [showSidemenu, setShowSidemenu] = useState(true);   
  const [input, setInput] = useState("");
  const [input2, setInput2] = useState("");  // for regen
  const [functions, setFunctions] = useState(defaultFunctions);  
  const [sizes, setSizes] = useState(defaultSizes);
  const [models, setModels] = useState(defaultModels);      
  const [temperatures, seTemperatures] = useState(defaultTemperatures);
  const [appName, setAppName] = useState("而知之"); // 升
  const [version, setVersion] = useState("2.0.3");
  const [deploymentDomain, setDeploymentDomain] = useState("xplayonline.com");
  const [gptver, setGptver] = useState("4.0");  //4.0
  const [refRate, setRefRate] = useState("30");    
  const [bounty, setBounty] = useState("3");
  const [price, setPrice] = useState("37");
  const [advertisement, setAdvertisement] = useState("");    
  const [currentFunction, setCurrentFunction] = useState("ChatGPT");
  const [currentSize, setCurrentSize] = useState("1024x1024");
  const [currentModel, setCurrentModel] = useState("gpt-3.5-turbo-instruct");   // gpt-3.5-turbo-instruct
  const [temperature, setTemperature] = useState("0.5");       

  // 人非生而知之者，孰能无惑？惑而不从师，其为惑也，终不解矣！查看惑答记录! 享受免费特别服务!
  // ChatGPT 是一个专业的在线问答系统，提供专业的技术支持。我们的团队由专业的科学家、工程师和计算机专家组成，为您提供最优质的服务。我们的服务是合法的，不会以任何形式欺骗或骗取您的钱财。如果您有任何疑问，可以随时联系我们的客服，我们将竭诚为您服务。
  // 人工图像生成功能一分钱一分货
  // 热烈庆祝六一国际儿童节！半价一周！
  // 人类语言成为计算机语言！说什么有什么！尽管说！如,“画栋豪宅”，“娶个美女”，“来点现金”
  // 注意：绘画时将菜单功能选为‘绘画’，提问时选为‘文本’
  // const chinesePhrase = `\n\n 注册即获 ${bounty} 个题目免费。目前每购买 ${price} 道题仅一美元（人民币一毛九一题），可用微信支付或支付宝付款。之后价格酌情调整。\n\n欢迎转发！获 ${refRate}% 收益！方法见推荐系統！`
  // const chinesePhrase = `\n\n 注册即获 ${bounty} 个题目免费。欢迎转发！获 ${refRate}% 收益！方法见推荐系統！`  
  const chinesePhrase = `\n\n 今天免费注册试用！转发者获 ${refRate}% 收益！方法见推荐系統！`    
  // let myMessage = `How can I help you today? 第一代文本嵌入模型退役!\n最新推出第二代美国人工智能实验室 OpenAI ChatGPT 和 DALL.E 绘画与变图功能！方法见功能菜单！\n\nEveryone is entitled to ${bounty} free questions or images, after which ${price} questions can be purchased for only $1 USD.` + chinesePhrase;  
  let myMessage = `How can I help you today? \n最新推出第二代美国 OpenAI ChatGPT DALL.E，和SORA！\n文本，绘画，变图, 视频生成以及PDF摘要！方法见功能菜单！\n\nTake advantage of free trials today for chat questions, image creation, image variations, video generation and PDF summarizer!` + chinesePhrase;    
  // if( advertisement !== null && advertisement !== ""  )
  // {
  //   myMessage += "\n\n" + advertisement;
  // }
  const [chatLog, setChatLog] = useState([
    {
      user: "gpt",    
      message: myMessage,
      image: imageURL
    },
    {
      user: "me",    
      message: `I would like to use ChatGPT ${gptver}, DALL.E, SORA and PDF summarizer!`,
      image: imageURL
    }  
    ]);     

    // useEffect(() => {
    //   axios.get(`${_PDP_}/api/stock`)
    //     .then(response => {
    //       setStockPrice(response.data.price);
    //       setChangePercent(response.data.changePercent);
    //       setMarketStatus(response.data.status);
    //       setStock(`MSFT: ${stockPrice} (${changePercent} (${marketStatus})`);    
    //     })
    //     .catch(error => console.log(error));
    // }, []);  


  // Define an array of image URLs
  const imageUrls = [shengChineseCode1, shengChineseCode2, shengChineseCode3, shengChineseCode4];

  // Initialize state to hold the index of the current image
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Function to handle image rotation
  const rotateImage = () => {
    // Increment the image index or reset to 0 if it exceeds the length of the array
    setCurrentImageIndex(currentImageIndex === imageUrls.length - 1 ? 0 : currentImageIndex + 1);
  };

  // useEffect hook to rotate the image each time the component mounts or updates
  useEffect(() => {
    const intervalId = setInterval(rotateImage, 5000); // Rotate image every 5 seconds
    return () => clearInterval(intervalId); // Cleanup function to clear the interval
  }, [currentImageIndex]); // Trigger useEffect when currentImageIndex changes



  useEffect(() => {
    const intervalId = setInterval(() => {
       console.log("Hiding menu");      
      setShowMenu(false);
    }, 60000*30);
    return () => {
      console.log("Clearing timeout");      
      clearInterval(intervalId);
    }
  }, []);  
  
  // use effect run once when app loads
  useEffect(()=>{
    console.log("Running initial effects");
    getConfig();
    getEngines();    
  }, []);  


  const toggleForm = (formName) => {
    setCurrentForm (formName);
  }

  function hideMenu() {
    setShowMenu(false);
    setShowSidemenu(false);
    const email = loggedInUser || localStorage.getItem('existingEmail') || "";        
    const msgToUser = `hideMenu() called`;
    logMessage(msgToUser, email); 
  }

  // clear chats
  function clearChat() {
    setChatLog([]);
    setImageURL("");
    setFile("");
    clearFileSelection();
    if(loggedInUser)
    {
      if (currentFunction.includes("DALL.E Create Image"))
      {
        getCoverGirl("Create Image");
      }
      else if (currentFunction.includes("DALL.E Image Variation"))
      {
        getCoverGirl("Image Variation");
      }
    }
    const email = loggedInUser || localStorage.getItem('existingEmail') || "";        
    const msgToUser = `clearChat() called`;
    logMessage(msgToUser, email); 
  }

  // toggleMenu
  const toggleMenu = () => {
    setShowMenu(!showMenu); // toggle form visibility when button is clicked

    const email = loggedInUser || localStorage.getItem('existingEmail') || "";    
    const action = showMenu ? "hide menu" : "show menu";
    const msgToUser = `toggleMenu() called to ${action}`;
    logMessage(msgToUser, email); 
  };    

  // toggleSidemenu
  const toggleSidemenu = () => {
    setShowSidemenu(!showSidemenu); // toggle form visibility when button is clicked
  };        
  
  function getConfig() {
    fetch(`${_PDP_}/api/server-config`)
      .then(res=> res.json()) // parse response as JSON
      .then(data=> {        
        console.log(data.appName + data.version + data.advertisement);
        setAppName(data.appName);
        setVersion(data.version);
        setGptver(data.gptver);        
        setBounty(data.bounty);
        setPrice(data.price);            
        setAdvertisement(data.advertisement);        
        // const myStr = `${appName} ${version} ${advertisement}`;
         const myStr = `${appName} ${version}`;
        setAppBrand(myStr);
      })
      .catch(error=> console.error(error));
  }

  function getEngines() {    
    fetch(`${_PDP_}/api/models`)
      .then(res=> res.json()) // parse response as JSON
      .then(data=> {
        console.log(data.models)
        // setModels(data.models)
      })
      .catch(error=> console.error(error));
  }

  function stringToBytes(input) {
    let totalBytes = 0;
    for (let i = 0; i < input.length; i++) {
      const charCode = input.charCodeAt(i);
      if (charCode <= 0x7f) {
        totalBytes += 1; // 1-byte characters
      } else if (charCode <= 0x7ff) {
        totalBytes += 2; // 2-byte characters
      } else if (charCode <= 0xffff) {
        totalBytes += 3; // 3-byte characters
      } else {
        totalBytes += 4; // 4-byte characters (surrogate pairs)
        i++; // advance to next character
      }
    }
    return totalBytes;
  }  

  function getMaxTokensById(id) {
    const model = defaultModels.find(m => m.id === id);
    return model ? model.max_tokens : 0;
  }    

  function isDrawing(input) {
    const keywords = ["DRAW", "draw", "Draw", "image", "picture", "绘", "画", "图", "像"];
  
    for (const keyword of keywords) {
      if (input.includes(keyword)) {
        return true;
      }
    }
  
    return false;
  }
  

  //  This code will call preventDefault() only if e is defined, and will do nothing if it's undefined. This protects against potential errors that could occur if e is not defined, for example if handleSubmit() is called from outside an event handler where e is not available.  
  async function handleSubmit(e)
  {
    e?.preventDefault();    

    // const inputValue = e.target.value;
    // alert(`GJ DEBUG 2, input= ${input}, input2= ${input2}, inputValue= ${inputValue}`);

    // don't send empty questions
    if (input.trim() === '' && !currentFunction.includes("DALL.E Image Variation")) {
      return;
    }    

    // send quection
    const email = loggedInUser || localStorage.getItem('existingEmail') || "";    
    if (!email)
    {
      // Please log in
      const msgToUser = 'Just a friendly reminder！Authentication required. Please attempt to login. \n\n温馨提示！需要身份验证，请尝试登录。注意：绘画或变图时将左侧菜单中功能选为“绘画”或“变图”，提问时选为“文本问答”。';
      promptMessage(msgToUser, email);
      return;
    }

    setQuestion(input);

    const func = currentFunction.includes("ChatGPT")
    ? "chatgpt"
    : currentFunction.includes("DALL.E Create Image")
    ? "dall.e-creation"
    : "dall.e-variation";
  
    sendQuestion(func, input, email);

    const inputBytes = stringToBytes(input);
    const graphemeSplitter = new GraphemeSplitter();
    const inputGraphemes = graphemeSplitter.countGraphemes(input);    

    // Auto clearChat when chatLog too long
    const messages2 = chatLog.map((message)=> message.message).join("\n") 
    const bytes2 = stringToBytes(messages2);
    // alert(`GJ DEBUG 3, input= ${input}, bytes2= ${bytes2}`);    
    // not clearchat for ID 228, hzkj2020@163.com
    if (bytes2 > 4096 && email && email.indexOf("hzkj2020@163.com") === -1) {
      clearChat();

      // handle error
      let chineseStatement = '已自动清屏，因输入文本累积太长。';
      const msgToUser = `The screen is automatically cleared when the input accumulated becomes too long. \n\n${chineseStatement}`;
      logMessage(msgToUser, email); 
      setChatLog([{ user: "gpt", message: `${msgToUser}`, image: `${imageURL}`}])
      return;
    }    

    // original code
    let chatLogNew = [...chatLog, { user: "me", message: `${input}`, image: `${imageURL}`}]; 
    // alert(`GJ DEBUG 3, input= ${input}, input2= ${input2}`);
    setInput("");     
    setChatLog(chatLogNew);

    // fetch response to the api combining the chat log array of messages and sending it as message to localhost:port as a post
    const max_tokens = getMaxTokensById(currentModel);
    // alert(`currentModel= ${currentModel}, temperature= ${temperature}, max_tokens= ${max_tokens}`);

    let messages = chatLogNew.map((message)=> message.message).join("\n")

    if (currentFunction.includes("ChatGPT"))
    {
      // 0) Clear image
      setImageURL("");
      // GJ important point - here messages is conversasion history for this thread of chat until cleared by the +New Chat button, so that ChatGPT remembers history
      // message: input, // never use input alone which starts a brand new question without history so the bot forgets about the past      

      if(isDrawing(input))
      {
        let chineseStatement = '温馨提示！如果你想绘画或变图，请将左侧菜单中 OpenAI 功能选项切换到 DALL.E “绘画”或“变图”，然后在下方提问处描述图像即可， 比如："画朵高清玫瑰，但不要清高！"，或：“变图”。可以点击左下方三点 “...” 按钮打开菜单。';
        const msgToUser = `Just a friendly reminder, if you'd like to create a drawing, please switch the OpenAI Function option to "DALL.E Create Image" or "DALL.E Image Variation". \n\n${chineseStatement}`;
        logMessage(msgToUser, email); 
        setChatLog([{ user: "gpt", message: `${msgToUser}`, image: `${imageURL}`}])
        return;  
      }
    }
    else if (currentFunction.includes("DALL.E Create Image")) //  || currentFunction.includes("DALL.E Image Variation")
    {
      // 0) Clear image
      setImageURL("");

      // only show input, no history
      chatLogNew = [{ user: "me", message: `${input}`, image: `${imageURL}`}];
      setChatLog(chatLogNew);  

      // 1) only use current input not the accumulated messages for history
      messages = input; 

      if(!isDrawing(input))
      {
        let chineseStatement = '温馨提示！如果你想聊天与问答，请将左侧菜单中 OpenAI 功能 选择切换到 "ChatGPT 文本问答"。可以点击左下方三点 “...” 按钮打开菜单。';
        const msgToUser = `Just a friendly reminder, if you'd like to use ChatGPT, please switch the OpenAI Function option to "ChatGPT 文本问答". \n\n${chineseStatement}`;
        logMessage(msgToUser, email); 
        setChatLog([{ user: "gpt", message: `${msgToUser}`, image: `${imageURL}`}])      
        return;  
      }
    }

    const endpoint = `${_PDP_}/api/chatgpt`;
    const response = await fetch (endpoint, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem('token')}`, // include token in header        
      },
      body: JSON.stringify({
        message: messages,
        currentFunction,
        currentSize,        
        currentModel, 
        temperature,
        max_tokens,        
        inputBytes,
        inputGraphemes,
        questionid,
      })
    });

    if (response.ok) {
      const data = await response.json();
      const msgToUser = `User '${email}' has received and shown on screen: \n\n${data.message}`;

      if (currentFunction.includes("ChatGPT"))
      {
        // 0) Clear image
        setImageURL("");

        // 1) Show message to user sent from ChatGPT!
        setChatLog([...chatLogNew, { user: "gpt", message: `${data.message}`, image: `${imageURL}`}])    
      }
      else if (currentFunction.includes("DALL.E Create Image"))
      {
        // 0) Download image        
        handleImageDownload(input);

        // 1) Show imageURL to user sent from ChatGPT!
        // setChatLog([...chatLogNew, { user: "gpt", message: `${data.message}`, image: `${imageURL}`}])    
      }  
      else if (currentFunction.includes("DALL.E Image Variation"))
      {
        // 0) Download image Variation      
        handleImageDownload("变图");
      } 

      // console.log(data.message);
      // 2) Log what user sees to server for debugging purpoes
      logMessage(msgToUser, email); 

      // 3) Update the decreased balance
      setBalance(data.balance);  

      // 4) flip to Payments for user convience when the following message occurs
      if (msgToUser.includes("Your account balance is currently at 0"))
      {
        setShowMenu(true);
        setShowSidemenu(false);
        setCurrentForm("payments");
      }

    } else {
      // handle error
      let chineseStatement = '很抱歉！输入太长或存在问题。点击“开新篇”来清空屏幕! 有些模型不接受太长输入。遇到此情况，请选择原来设定的默认人工智能模型 gpt-3.5-turbo-instruct，刷新屏幕或者点击“开新篇”按钮。如果问题依然存在，请将问题提交客服，我们将竭诚为您服务。';
      const msgToUser = `Access Status: ${response.status} - ${response.statusText}. \n\n${chineseStatement}`;
      console.log(msgToUser);
      logMessage(msgToUser, email); 

      setImageURL("");
      imageURL="";
      setChatLog([...chatLogNew, { user: "gpt", message: `${msgToUser}`, image: `${imageURL}`}])      
    }       
  }

  const sendQuestion = async (func, question, userEmail) => {
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      };

      // alert(localStorage.getItem('token'));

      // Save question and get questionid back for ask-chatgpt to save answer
      const endpoint = `${_PDP_}/api/question`;
      const response = await axios.post(endpoint, {func, question, userEmail}, { headers: headers });
      console.log(response.data);

      if (response.data.success) {
        setQuestionid(response.data.questionid)
        // alert(response.data.questionid);
      } else {
      }
  
    } catch (error) {
      console.error(error);
    }
  };  

  const getCoverGirl = async (functionString) => {
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      };  
      const endpoint = `${_PDP_}/api/get-cover-girl`;      
      const response = await axios.get(endpoint, { headers: headers,
        responseType: 'arraybuffer',
      });

      // let msgToUser = "";
      // if (response.data.success) {
      //   msgToUser = `${response.data.message}`;
      // } else {
      //   msgToUser = `${response.data.message}`;
      // }      

      const blob = new Blob([response.data], { type: 'image/png' });
      const imageUrl = URL.createObjectURL(blob);
      setImageURL(imageUrl);
      const _imageURL = "";
      const data_message = "";

      let _message = "Draw a cover girl or a bull! 画个封面女郎或牛郎！";
      if (functionString.includes("Create Image"))
      {
      }
      else if (functionString.includes("Image Variation"))
      {
        _message = "Image variation! \n\n变换一幅图！先上传您的 .png 类型图片，然后按 “变”！可直接回车或按箭头。";
      }

      setChatLog([{ user: "me", message: `${_message}`, image: `${_imageURL}`}, { user: "gpt", message: `${data_message}`, image: `${imageUrl}`}])
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  };    

  const logMessage = async (message, userEmail) => {
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      };

      const endpoint = `${_PDP_}/api/log-message`;
      const response = await axios.post(endpoint, { message, userEmail }, { headers: headers });
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };  

  // To make the chat-log element automatically scroll to show the latest messages, 
  // you can add a useEffect hook in your functional component 
  // and use the ref object to access the scrollTop and scrollHeight properties of the div element.
  const chatLogRef = useRef(null);
  useEffect(() => {
    chatLogRef.current.scrollTop = chatLogRef.current.scrollHeight;
  }, [chatLog]);

// The reason why input is not set to input2 in your handleRegenerate function is because of the way React's useState hook works. When you call setInput(input2), it doesn't immediately update the input state to the value of input2. Instead, it queues up a state update that will be applied on the next render.
// So when you call handleSubmit immediately after calling setInput(input2), the input state hasn't been updated yet. Therefore, the handleSubmit function will still use the old value of input instead of the updated value of input.
// One solution to this problem is to pass the updated value of input2 directly to the handleSubmit function, instead of relying on the input state to update first. 
  
  const handleRegenerate = () => {
    // generate a new response
    // alert(`GJ DEBUG 1, input= ${input}, input2= ${input2}`);

    const fakeEvent = { preventDefault: () => {} }; // create fake event object
    const fakeEventWithInput2 = { ...fakeEvent, target: { value: input2 } }; // create copy of fakeEvent with updated value of input2
    handleSubmit(fakeEventWithInput2); // call handleSubmit with fakeEventWithInput2
  };      

  const handleImageDownload = async (myInput) => {    
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      };  
      const endpoint = `${_PDP_}/api/get-image`;      
      const response = await axios.get(endpoint, { headers: headers,
        responseType: 'arraybuffer',
      });

      const blob = new Blob([response.data], { type: 'image/png' });
      const imageUrl = URL.createObjectURL(blob);
      setImageURL(imageUrl);
      const _imageURL = "";
      const data_message = "";
      setChatLog([{ user: "me", message: `${myInput}`, image: `${_imageURL}`}, { user: "gpt", message: `${data_message}`, image: `${imageUrl}`}])
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  };

  const email = loggedInUser || localStorage.getItem('existingEmail') || "";  

  function promptMessage(msgToUser, email) {
    if (!msgToUser)
      return;
    logMessage(msgToUser, email);
    setChatLog([{ user: "gpt", message: `${msgToUser}`, image: `${imageURL}` }]);
  }  

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Your code to handle form submission
    handleFileUpload(e);
  };  

  const handleFileUpload = (event) => {
    event.preventDefault();
    // const file = event.target?.files?.[0];        
    // alert(file.name);        

    if (!file) {
      let chineseStatement = '温馨提示！请选择要上传的图片文件 .png 类型！';
      const msgToUser = ` \n\n${chineseStatement}`;
      promptMessage(msgToUser, email);
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    const headers = {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    };     

    const endpoint = `${_PDP_}/api/upload-image-file`;
    axios.post(endpoint, formData, { headers: headers})
      .then(response => {
        if (response.data.success) {
          const msgToUser = `${response.data.message}`;
          promptMessage(msgToUser, email);    
          handleImageDownload();
        } else {
          const msgToUser = `${response.data.message}`;
          promptMessage(msgToUser, email);    
        }
      })
      .catch(error => {
        console.log(error);
        const msgToUser = `An error occurred while uploading the file: ${error.message}`;
        promptMessage(msgToUser, email);    
      });
  };      

  const onChange = (e) =>
  {
    let myfile = e.target?.files?.[0];
    // alert(myfile.name);
    setFile(myfile);
  }

  const clearFileSelection = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };  

  const handleVariation = (e) => {

    if (!email)
    {
      // Please log in
      const msgToUser = 'Just a friendly reminder！Authentication required. Please attempt to login. \n\n温馨提示！需要身份验证，请尝试登录。注意：绘画或变图时将左侧菜单中功能选为“绘画”或“变图”，提问时选为“文本问答”。';
      promptMessage(msgToUser, email);
      return;
    }

    setInput("变图");
    handleSubmit(e);
  };      
  
  return (

    // <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn }}>

      <div className="App">

      {showMenu && (
        <>
          {currentForm === "login" ? (            
            <Login loggedInUser={loggedInUser} setLoggedInUser={setLoggedInUser} setBalance={setBalance} onFormSwitch={toggleForm} hideMenu={hideMenu} clearChat={clearChat} toggleMenu={toggleMenu}/>
          ) : currentForm === "register" ? (
            <Register setBalance={setBalance} onFormSwitch={toggleForm} />
          ) : currentForm === "forgotpassword" ? (
            <ForgotPassword onFormSwitch={toggleForm} /> 
          ) : currentForm === "resetpassword" ? (
            <ResetPassword onFormSwitch={toggleForm} />
          ) : currentForm === "verifyemail" ? (
            <VerifyEmail onFormSwitch={toggleForm} />
          ) : currentForm === "comments" ? (
            <Comments loggedInUser={loggedInUser} setLoggedInUser={setLoggedInUser} onFormSwitch={toggleForm} />
          ) : currentForm === "spintrol" ? (
            <Spintrol loggedInUser={loggedInUser} setLoggedInUser={setLoggedInUser} setBalance={setBalance} onFormSwitch={toggleForm} />            
          ) : currentForm === "disclaimer" ? (
            <Disclaimer onFormSwitch={toggleForm} />  
          ) : currentForm === "usersviewer" ? (
            <UsersViewer setBalance={setBalance} onFormSwitch={toggleForm} />
          ) : currentForm === "webscrapingsviewer" ? (
            <WebScrapingsViewer setBalance={setBalance} onFormSwitch={toggleForm} />            
          ) : currentForm === "usermanualsviewer" ? (
            <UserManualsViewer setBalance={setBalance} onFormSwitch={toggleForm} />
          ) : currentForm === "questionsviewer" ? (
            <QuestionsViewer setBalance={setBalance} onFormSwitch={toggleForm} />
          ) : currentForm === "referralsviewer" ? (
            <ReferralsViewer loggedInUser={loggedInUser} setBalance={setBalance} onFormSwitch={toggleForm} />
          ) : currentForm === "websitesviewer" ? (
            <WebsitesViewer loggedInUser={loggedInUser} setBalance={setBalance} onFormSwitch={toggleForm} />                        
          ) : currentForm === "distributorsviewer" ? (
            <DistributorsViewer loggedInUser={loggedInUser} setBalance={setBalance} onFormSwitch={toggleForm} />
          ) : currentForm === "statisticsviewer" ? (
            <StatisticsViewer loggedInUser={loggedInUser} setBalance={setBalance} onFormSwitch={toggleForm} />                        
          ) : currentForm === "paymentsviewer" ? (
            <PaymentsViewer setBalance={setBalance} onFormSwitch={toggleForm} />            
          ) : currentForm === "commentsviewer" ? (
            <CommentsViewer setBalance={setBalance} onFormSwitch={toggleForm} />                        
          ) : currentForm === "answersviewer" ? (
            <AnswersViewer setBalance={setBalance} onFormSwitch={toggleForm} />
          ) : currentForm === "imagesviewer" ? (
            <ImagesViewer setBalance={setBalance} onFormSwitch={toggleForm} />
          ) : currentForm === "officialanswersviewer" ? (
            <OfficialAnswersViewer setBalance={setBalance} onFormSwitch={toggleForm} />                        
          ) : currentForm === "serverlog" ? (
            <ServerLog setBalance={setBalance} onFormSwitch={toggleForm} />
          ) : currentForm === "payments" ? (
            <Payments setBalance={setBalance} onFormSwitch={toggleForm} />
          ) : currentForm === "wechatgroup" ? (
            <WeChatGroup loggedInUser={loggedInUser} setBalance={setBalance} onFormSwitch={toggleForm} />            
          ) : currentForm === "wechatpay" ? (
            <WeChatPay loggedInUser={loggedInUser} setBalance={setBalance} onFormSwitch={toggleForm}  />
          ) : (
            <AliPay loggedInUser={loggedInUser} setBalance={setBalance} onFormSwitch={toggleForm}  />
            )

          } 
        </>
      )}                        
      

      {/* <Advertisement ad={advertisement} />           */}
      {/* {showMenu && <ApplePay setBalance={setBalance} onFormSwitch={toggleForm} />}      */} 


      <aside className="sidemenu"  style={{ display: showSidemenu ? 'block' : 'none' }}>

        <Heading color="crimson" style={{ position: "relative", display: "inline-block" }}>
          <img src={imageUrls[currentImageIndex]} alt="Icon" style={{ marginRight: "-0.2rem", verticalAlign: "bottom" }} /> {/* Add your icon here: top, middle, bottom */}
          {appBrand}
          <span style={{ position: "absolute", top: "-0.3rem", right: "9.6rem", fontSize: "80%", color: "white" }}> 
            &reg;
          </span>
          <span>OpenAI models</span>
        </Heading>

        <div className='sidemenu-deployment-domain' style={{ marginBottom: '10px' }}>
          <span title='website'>{deploymentDomain}</span>
        </div>

        <div className='sidemenu-current-user'style={{ marginBottom: '1px' }}>
          <span title='Logged-in User email'>{email}</span>
        </div>        

        <div className='sidemenu-current-user'style={{ marginBottom: '20px' }}>
          {balance !== "" && (
            <span title='Current Account Balance'>balance 余额：{balance}</span>
          )}
        </div>

        <div className='side-menu-dots' onClick={toggleMenu}>
          <span title='Show/Hide Menu 开/关目录'><strong></strong> {showMenu ? '《 关闭目录' : '》打开目录'}</span> 
        </div>

        <div className='side-menu-button' onClick={clearChat} style={{ marginBottom: '10px' }}>
          <span>+</span>New chat 开新篇（清屏）
        </div>
                        {/* functions */}
        <div className='side-menu-button' style={{ marginBottom: '10px' }}> 
          <span>+</span>
          <span style={{ paddingBottom: '10px' }}>OpenAI Functions 功能</span>
          <select className="select-func" onMouseUp={clearChat} onChange={(e)=>{
            setCurrentFunction(e.target.value)
            }}>
            <option selected >ChatGPT 文本问答</option>
            {functions.map((func, index) => (
                <option key= {func.id} value = {func.id}>{func.id}
                </option>
              ))}
          </select>
        </div>

                        {/* Upload File */}
        {currentFunction.includes("DALL.E Image Variation") && 
        <div className='side-menu-button' style={{ marginBottom: '10px' }}> 
          <span>+</span>
          <span style={{ paddingBottom: '10px' }}>Upload File 上传图片文件</span>

          <form onSubmit={handleFormSubmit}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <input type="file" name="file" id="fileInput" accept="image/png" onChange={onChange} ref={fileInputRef} style={{ marginBottom: '10px' }} />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button type="submit" style={{ height: '20px', width: '100px', backgroundColor: '#007bff', color: '#fff', borderRadius: '5px', padding: '5px', border: 'none', marginLeft: '15px', marginBottom: '10px' }}>Upload 上传</button>
                <button type="button" onClick={(e) => handleVariation(e)} style={{ height: '20px', width: '100px', backgroundColor: '#007bff', color: '#fff', borderRadius: '5px', padding: '5px', border: 'none', marginLeft: '15px' }}>Variation 变</button>
              </div>              
              <label htmlFor="fileInput" style={{ marginTop: '0px', marginBottom: '0px', fontSize: '12px'  }}>注：点击“选择文件” （Choose File） 选择 .png 类型图片文件，按 “上传”，完毕后点击 “变”！ </label>
            </div>
          </form>

        </div>}

                        {/* sizes */}
        <div className='side-menu-button' style={{ marginBottom: '10px' }}> 
          <span>+</span>
          <span style={{ paddingBottom: '10px' }}>DALL.E Sizes 绘画分辨率</span>
          <select className="select-size" onChange={(e)=>{
            setCurrentSize(e.target.value)
            }}>
            <option selected >1024x1024 高</option>
            {sizes.map((size, index) => (
                <option key= {size.id} value = {size.id}>{size.id}
                </option>
              ))}
          </select>
        </div>

                        {/* models */}
        <div className='side-menu-button' style={{ marginBottom: '10px' }}> 
          <span>+</span>
          <span style={{ paddingBottom: '10px' }}>Models 人工智能模型</span>
          <select className="select-model" onChange={(e)=>{
            setCurrentModel(e.target.value)
            }}>
            <option selected >gpt-3.5-turbo-instruct</option>
            {models.map((model, index) => (
                <option key= {model.id} value = {model.id}>{model.id}
                </option>
              ))}
          </select>
        </div>

        <div className='side-menu-button' style={{ marginBottom: '20px' }}>
          <span>+</span>
          <span style={{ paddingRight: '10px' }}>Temperature 温度值(热情度)</span>          
          <select className="select-temperature" onChange={(e)=>{
            setTemperature(e.target.value)
            }}>
            <option selected >0.5</option>
            {temperatures.map((temperature, index) => (
                <option key= {temperature.id} value = {temperature.id}>{temperature.id}
                </option>
              ))}
          </select>
        </div>


        {/* <div className='side-menu-stock'>
          <span title='stock'> {stock}</span>          
        </div>                 */}



      </aside>

      <section className="chatbox">

        <div className='chat-log' ref={chatLogRef}>
          {chatLog.map((message, index)=>(
              <ChatMessage key = {index} message={message}/>
          ))}
        </div>

        <div className="regenerate-holder">
          <button className="regenerate-btn" onClick={handleRegenerate}>
          Regenerate response 重新生成
          </button>
        </div>

        <div className='chat-input-holder'>   
              <div className='side-menu-newchat' onClick={toggleSidemenu} style={{ position: 'fixed', bottom: '50px', left: '0px' }}>
                <span title='Show/Hide Sidemenu 开/关菜单'><strong>...</strong> {showSidemenu ? '关闭菜单' : ''}</span>          
              </div>

              <form onSubmit={handleSubmit}>
                <textarea
                  rows="2"
                  value={input}
                  onChange={(e) => {setInput(e.target.value);setInput2(e.target.value);}}
                  className='chat-input-textarea'
                  placeholder='Send a message 在此提问，描述绘画，变图或生成视频，回车或按箭头 ...'
                  onKeyPress={(e) => e.key === 'Enter' && handleSubmit(e)} // check for Enter key press; // &#8710;  &#9658, &#8811;, &#8663; &#187; &#62;
                />

                <button className='submit-btn' type='submit'>
                  <span className='icon'>&#62;</span> 
                </button>

              </form>     
        </div>

      </section>
      </div>

    // </AuthContext.Provider>    
  
  );
}


const ChatMessage = ({ message }) => {
  const [displayImage, setDisplayImage] = useState(true);
  const [displayedMessage, setDisplayedMessage] = useState('');
  const isCode = /\S*[^\w\s()[\]{}.,;+\-*/<>!=?|^&%~@]\S*/.test(displayedMessage);  

  useEffect(() => {
    if (message.image) {
      setTimeout(() => {
        setDisplayImage(true);
      }, 2000); // Delay before showing the image (2 seconds in this example)  
    }
  }, [message.image]);   

  useEffect(() => {
    if (message.user === 'gpt') {
      setDisplayedMessage('');      
      let i = 0;
      const intervalId = setInterval(() => {
        setDisplayedMessage((prevMessage) => prevMessage + message.message.charAt(i));
        i++;
        if (i === message.message.length) {
          clearInterval(intervalId);
        }
      }, 50);

      setTimeout(() => {
        setDisplayedMessage(message.message);
      }, 50 * message.message.length + 500);

      return () => {
        clearInterval(intervalId);
        clearTimeout();
      };

    } else {
      setDisplayedMessage(message.message);
    }
  }, [message.message, message.user]);

  return (
    <div className={`chat-message ${message.user === 'gpt' && 'chatgpt'}`}>
      <div className="chat-message-center">

        <div className={`avatar ${message.user === 'gpt' && 'chatgpt'}`}>
          {message.user === 'gpt' && (
            <svg
              width={41}
              height={41}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              strokeWidth={1.5}
              className="h-6 w-6"
            >
            <path
                d="M37.532 16.87a9.963 9.963 0 0 0-.856-8.184 10.078 10.078 0 0 0-10.855-4.835A9.964 9.964 0 0 0 18.306.5a10.079 10.079 0 0 0-9.614 6.977 9.967 9.967 0 0 0-6.664 4.834 10.08 10.08 0 0 0 1.24 11.817 9.965 9.965 0 0 0 .856 8.185 10.079 10.079 0 0 0 10.855 4.835 9.965 9.965 0 0 0 7.516 3.35 10.078 10.078 0 0 0 9.617-6.981 9.967 9.967 0 0 0 6.663-4.834 10.079 10.079 0 0 0-1.243-11.813ZM22.498 37.886a7.474 7.474 0 0 1-4.799-1.735c.061-.033.168-.091.237-.134l7.964-4.6a1.294 1.294 0 0 0 .655-1.134V19.054l3.366 1.944a.12.12 0 0 1 .066.092v9.299a7.505 7.505 0 0 1-7.49 7.496ZM6.392 31.006a7.471 7.471 0 0 1-.894-5.023c.06.036.162.099.237.141l7.964 4.6a1.297 1.297 0 0 0 1.308 0l9.724-5.614v3.888a.12.12 0 0 1-.048.103l-8.051 4.649a7.504 7.504 0 0 1-10.24-2.744ZM4.297 13.62A7.469 7.469 0 0 1 8.2 10.333c0 .068-.004.19-.004.274v9.201a1.294 1.294 0 0 0 .654 1.132l9.723 5.614-3.366 1.944a.12.12 0 0 1-.114.01L7.04 23.856a7.504 7.504 0 0 1-2.743-10.237Zm27.658 6.437-9.724-5.615 3.367-1.943a.121.121 0 0 1 .113-.01l8.052 4.648a7.498 7.498 0 0 1-1.158 13.528v-9.476a1.293 1.293 0 0 0-.65-1.132Zm3.35-5.043c-.059-.037-.162-.099-.236-.141l-7.965-4.6a1.298 1.298 0 0 0-1.308 0l-9.723 5.614v-3.888a.12.12 0 0 1 .048-.103l8.05-4.645a7.497 7.497 0 0 1 11.135 7.763Zm-21.063 6.929-3.367-1.944a.12.12 0 0 1-.065-.092v-9.299a7.497 7.497 0 0 1 12.293-5.756 6.94 6.94 0 0 0-.236.134l-7.965 4.6a1.294 1.294 0 0 0-.654 1.132l-.006 11.225Zm1.829-3.943 4.33-2.501 4.332 2.5v5l-4.331 2.5-4.331-2.5V18Z"
                fill="currentColor"
              />
            </svg>      
          )}
        </div>

        <div style={{ whiteSpace: 'pre-line', tabSize: 2 }} className="message">
          {/* <pre>{displayedMessage}</pre> */}
          {displayedMessage}        
        </div>

        <div className="image-typing-effect">
          {/* <button onClick={handleImageDownload}>Download Image</button> */}
          {message.image && <img src={message.image} alt="Downloaded Image" style={{width: '700px', height: 'auto', marginTop: '20px', opacity: displayImage ? 1 : 0, transition: 'opacity 12s ease-in-out'}} />}
        </div>

      </div>
    </div>
  );
};




// {isCode ? (
//   <pre>{displayedMessage}</pre>
// ) : (
//     {displayedMessage}
// )}







// original code
// const ChatMessage = ({message}) => {
//   return (
//   <div className={`chat-message ${message.user === "gpt" && "chatgpt"}`}>
//     <div className='chat-message-center'>
//       <div className={`avatar ${message.user === "gpt" && "chatgpt"}`}> 
//         { message.user === "gpt" && 
//                       <svg
//                       width={41}
//                       height={41}      
//                       fill="none"
//                       xmlns="http://www.w3.org/2000/svg"
//                       strokeWidth={1.5}
//                       className="h-6 w-6">
//                       <path
//                         d="M37.532 16.87a9.963 9.963 0 0 0-.856-8.184 10.078 10.078 0 0 0-10.855-4.835A9.964 9.964 0 0 0 18.306.5a10.079 10.079 0 0 0-9.614 6.977 9.967 9.967 0 0 0-6.664 4.834 10.08 10.08 0 0 0 1.24 11.817 9.965 9.965 0 0 0 .856 8.185 10.079 10.079 0 0 0 10.855 4.835 9.965 9.965 0 0 0 7.516 3.35 10.078 10.078 0 0 0 9.617-6.981 9.967 9.967 0 0 0 6.663-4.834 10.079 10.079 0 0 0-1.243-11.813ZM22.498 37.886a7.474 7.474 0 0 1-4.799-1.735c.061-.033.168-.091.237-.134l7.964-4.6a1.294 1.294 0 0 0 .655-1.134V19.054l3.366 1.944a.12.12 0 0 1 .066.092v9.299a7.505 7.505 0 0 1-7.49 7.496ZM6.392 31.006a7.471 7.471 0 0 1-.894-5.023c.06.036.162.099.237.141l7.964 4.6a1.297 1.297 0 0 0 1.308 0l9.724-5.614v3.888a.12.12 0 0 1-.048.103l-8.051 4.649a7.504 7.504 0 0 1-10.24-2.744ZM4.297 13.62A7.469 7.469 0 0 1 8.2 10.333c0 .068-.004.19-.004.274v9.201a1.294 1.294 0 0 0 .654 1.132l9.723 5.614-3.366 1.944a.12.12 0 0 1-.114.01L7.04 23.856a7.504 7.504 0 0 1-2.743-10.237Zm27.658 6.437-9.724-5.615 3.367-1.943a.121.121 0 0 1 .113-.01l8.052 4.648a7.498 7.498 0 0 1-1.158 13.528v-9.476a1.293 1.293 0 0 0-.65-1.132Zm3.35-5.043c-.059-.037-.162-.099-.236-.141l-7.965-4.6a1.298 1.298 0 0 0-1.308 0l-9.723 5.614v-3.888a.12.12 0 0 1 .048-.103l8.05-4.645a7.497 7.497 0 0 1 11.135 7.763Zm-21.063 6.929-3.367-1.944a.12.12 0 0 1-.065-.092v-9.299a7.497 7.497 0 0 1 12.293-5.756 6.94 6.94 0 0 0-.236.134l-7.965 4.6a1.294 1.294 0 0 0-.654 1.132l-.006 11.225Zm1.829-3.943 4.33-2.501 4.332 2.5v5l-4.331 2.5-4.331-2.5V18Z"
//                         fill="currentColor"
//                       />
//                     </svg>      
//         }
//       </div>
//       <div style={{ whiteSpace: 'pre-line', tabSize: 2 }} className="message">
//         {message.message}
//       </div>
//     </div>
//   </div>         
//   )
// }

export default App;
