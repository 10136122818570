export const getFormattedDate = (type) => {
  var date = new Date();
  var options = {
     year: 'numeric',
     month: '2-digit',
     day: '2-digit',
     hour: '2-digit',
     minute: '2-digit',
     second: '2-digit',
     timeZone: 'America/New_York',
     hour12: false // add this line to remove AM/PM      
  };
  var str = '';
  if (type === 0) {
     str = date.toLocaleString('en-US', options).replace(/[\/,:]/g, '_');
  } else if (type === 1) {
     str = date.toLocaleString('en-US', options);
  } else if (type === 2) {
     str = date.toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'America/New_York' });
  } else if (type === 3) {
     str = date.toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', timeZone: 'America/New_York' }).replace(/[\/,:]/g, '');
  }
  return str;
 };
  