import React, { useState } from 'react';
import { _PDP_ } from './global.js';


export const ResetPassword = (props) => {

  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const [newPass, setNewPass] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(email);
  
    if (newPass !== confirmPass) {
        setError('Passwords do not match 密码不一致');
        return;
    }  
    
    try {
      const response = await fetch(`${_PDP_}/api/reset-password/` + token, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, newPass, confirmPass }),
      });

      const data = await response.json();
      if (data.success) {
        setSuccess(data.message);
        setError(null);  
      } else {
        setError(data.message);
        setSuccess(null);        
      }

    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="auth-form-container">
        <h3>Reset Password 重设密码</h3>
        {error && <div className="error">{error}</div>}
        {success && <div className="success">{success}</div>}
        <form className="reset-form" onSubmit={handleSubmit}>
            {/* <label htmlFor ="email">Email 邮箱: (not required 不需要填)</label>
            <input value={email} onChange={(e)=>setEmail(e.target.value)} type="email" placeholder="youremail@qq.com" id="email" name="email" disabled/> */}

            <label htmlFor="token">Token 验证码: (received in the email sent 见邮件；如果沒有，按‘生成验证码’)</label>
            <input value={token} onChange={(e)=>setToken(e.target.value)} type="token" placeholder="token" id="token" name="token" required/> 

            <label htmlFor ="password">New Password 新密码:</label>
            <input value={newPass} onChange={(e)=>setNewPass(e.target.value)} type="password" placeholder="********" id="password" name="password" pattern="(?=.*\d)(?=.*[A-Z]).{8,}" title="Password must be at least 8 characters long and contain at least one uppercase letter and one number" required/>

            <label htmlFor ="confirmPassword">Confirm Password 密码确认:</label>
            <input value={confirmPass} onChange={(e)=>setConfirmPass(e.target.value)} type="password" placeholder="********" id="password" name="password" pattern="(?=.*\d)(?=.*[A-Z]).{8,}" title="Password must be at least 8 characters long and contain at least one uppercase letter and one number" required/>
            <button className="auth-form-button reset" type="submit">Reset Password 重设密码</button>
        </form>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <button className="link-btn" onClick={()=>props.onFormSwitch('forgotpassword')}>Request Token 生成验证码</button>
          <button className="link-btn" onClick={()=>props.onFormSwitch('login')}>Login 登录</button>             
        </div>
    </div>
  );
}

