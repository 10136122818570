import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { _PDP_ } from './global.js';

export const OfficialAnswersViewer = (props) => {
    
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [answers, setAnswers] = useState([]);

  const [editingRow, setEditingRow] = useState(null);  
  const [updatedData, setUpdatedData] = useState(answers); // where updatedData is the whole users array

  const [newUserid, setNewUserid] = useState('');        
  const [newQuestionid, setNewQuestionid] = useState('');      
  const [newSentcount, setNewSentcount] = useState('');      
  const [newStopsending, setNewStopsending] = useState('');      
  const [newUpdated, setNewUpdated] = useState('');        
  const [newTime, setNewTime] = useState('');
  const [newQuestion, setNewQuestion] = useState('');  
  const [newAnswer, setNewAnswer] = useState('');  

  const setEditingRowById  = id => {
    const row = updatedData.find(row => row.id === id);
    setNewUserid(row.userid);    
    setNewQuestionid(row.questionid);
    setNewSentcount(row.sentcount);
    setNewStopsending(row.stopsending);
    setNewUpdated(row.updated);
    setNewTime(row.time);
    setNewQuestion(row.question);
    setNewAnswer(row.answer);    

    setEditingRow(id);
  };  

  const handleSaveClick = async (id, newUserid, newQuestionid, newSentcount, newStopsending, newUpdated, newTime, newQuestion, newAnswer, props ) => {
    const newData = [...updatedData];
    const index = newData.findIndex(row => row.id === id);
    const oldRow = newData[index];
    const newRow = { ...oldRow, userid: newUserid, questionid: newQuestionid, sentcount: newSentcount, stopsending: newStopsending, updated: newUpdated, time: newTime, question: newQuestion, answer: newAnswer };    
    if (newRow.userid !== oldRow.userid ||
        newRow.questionid !== oldRow.questionid ||
        newRow.sentcount !== oldRow.sentcount ||
        newRow.stopsending !== oldRow.stopsending ||
        newRow.updated !== oldRow.updated ||        
        newRow.time !== oldRow.time || 
        newRow.question !== oldRow.question ||         
        newRow.answer !== oldRow.answer) 
      {
      newData[index] = newRow;
      setUpdatedData(newData);
      setEditingRow(null);

      // where newRow is updated to be pushed to sever

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      };      

      const data =
      {
        id: newRow.id,        
        userid: newRow.userid,        
        questionid: newRow.questionid,
        sentcount: newRow.sentcount,
        stopsending: newRow.stopsending,
        updated: newRow.updated,
        time: newRow.time,
        question: newRow.question,
        answer: newRow.answer        
      };      
      
      try {    
          const endpoint = `${_PDP_}/api/save-row-officailanswer`;
          const response = await axios.post(endpoint, data, { headers: headers });
          console.log(response.data);
          setSuccess(response.data.message);
          setError(null);
          props.setBalance(response.data.balance);          

        } catch (error) {
          setError(error.response.data.message);
          setSuccess(null);
        } 
      
      }
  };  

  const renderRow = row => {
    const { id } = row;
    if (editingRow === id) {
      return (
        <tr key={id}>
          <td>{id}</td>          
          <td><input className="input2" type="number" value={newUserid} onChange={e => setNewUserid(e.target.value)} /></td>          
          <td><input className="input2" type="number" value={newQuestionid} onChange={e => setNewQuestionid(e.target.value)} /></td>
          <td><input className="input2" type="number" value={newSentcount} onChange={e => setNewSentcount(e.target.value)} /></td>                    
          <td><input className="input2" type="number" value={newStopsending} onChange={e => setNewStopsending(e.target.value)} /></td>                    
          <td><input className="input2" type="number" value={newUpdated} onChange={e => setNewUpdated(e.target.value)} /></td>                                        
          <td><input className="input2" type="text" value={newTime} onChange={e => setNewTime(e.target.value)} /></td>                            
          <td><input className="input2" type="text" value={newQuestion} onChange={e => setNewQuestion(e.target.value)} /></td>          
          <td><input className="input2" type="text" value={newAnswer} onChange={e => setNewAnswer(e.target.value)} /></td>
          <td>
            <button className="save" onClick={() => handleSaveClick(id, newUserid, newQuestionid, newSentcount, newStopsending, newUpdated, newTime, newQuestion, newAnswer, props)}>Save</button>
            <button className="cancel" onClick={() => setEditingRow(null)}>Cancel</button>            
          </td>

        </tr>
      );
    } else {
      return (
        <tr key={id}>
          <td>{row.id}</td>
          <td>{row.userid}</td>          
          <td>{row.questionid}</td>
          <td>{row.sentcount}</td>                              
          <td>{row.stopsending}</td>                              
          <td>{row.updated}</td>                              
          <td>{row.time}</td>
          <td>{row.question}</td>          
          <td>{row.answer}</td>
          <td>
            <button className="edit" onClick={() => setEditingRowById(id)}>Edit</button>            
          </td>
        </tr>
      );
    }
  };
  
  useEffect(() => {
    fetchOfficialAnswers();
  }, []);


  const fetchOfficialAnswers = async () => {

    const endpoint = `${_PDP_}/api/officialanswers` ;
    const response = await fetch (endpoint, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem('token')}`, // include token in header        
      }
    });    

    if (response.ok) {
      const data = await response.json();      
      console.log(data.message);
      setSuccess(data.message);        
      setError(null);        
      setAnswers(data);  
      setUpdatedData(data);      
    } else {
      // handle error
      const msgToUser = `Please log in to get the required authentication. Access Status: ${response.status} - ${response.statusText}.`;      
      console.log(msgToUser);
      setSuccess(null);        
      setError(msgToUser);        
    }        
  };      

  const refreshOfficialAnswers = () => {
    fetchOfficialAnswers();
  }

  return (
    <div className="auth-form-container">
    <h3>OfficialAnswers Viewer</h3>   
    {error && <div className="error">{error}</div>}
    {success && <div className="success">{success}</div>}

    <div className="table-container">
      <table className="table-form">
        <thead>
          <tr>
            <th>ID</th>
            <th>UserId</th>            
            <th>QuestionId</th>
            <th>SentCount</th>
            <th>StopSending</th>                        
            <th>Updated</th>            
            <th>Time</th>
            <th>Question</th>            
            <th>Answer</th>
          </tr>
        </thead>
        <tbody>
          {updatedData.map(renderRow)}
        </tbody>
      </table>
    </div>
    <div style={{ marginTop: '20px' }}>
      <button className="link-btn" onClick={refreshOfficialAnswers}>Refresh</button>                 
      <button className="link-btn" onClick={()=>props.onFormSwitch('login')}>Login</button>             
    </div>
    </div>
    );  
}