import React, { useState } from 'react';
import axios from 'axios';
// import {useSelector} from "react-redux";

// import {url} from "../slices/api";
import usdtQRCode from "./images/usdt-qr-code.png";
import { _PDP_ } from './global.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWeixin } from '@fortawesome/free-brands-svg-icons'
import { faAlipay } from '@fortawesome/free-brands-svg-icons';
import { faApplePay } from '@fortawesome/free-brands-svg-icons';

const MinAmount = 3;
const AmountString = `${MinAmount}.00`;

export const Payments = (props) => {

  const [isDisabled, setIsDisabled] = useState(false);
  const [isProcessing, setProcessing] = useState(false);        

  const [amount, setAmount] = useState(AmountString);
  const [amountError, setAmountError] = useState('');

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);  

  const handleSubmit = async (e) => {
    e.preventDefault();    

    if (validateForm()) {

      setProcessing(true);
      
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      };
      
      const data = {
        amount
      };
      
      try {    
          const endpoint = `${_PDP_}/api/create-checkout-session`; 
          const response = await axios.post(endpoint, data, { headers: headers });
          const session  = response;
          console.log(response.data);
          setSuccess(response.data.message);
          setError(null);

          window.open(session.data.checkoutUrl, '_blank');                
          setProcessing(false);  
          // don't update balance until Stripe reported success
          // props.setBalance(session.data.balance);

        } catch (error) {
          setError(error.response.data.message);          
          setSuccess(null);
        }      


    }
  };



  const validateForm = () => {
    let isValid = true;

    if (!amount) {
      setAmountError('Please enter an amount 请输入金额');
      isValid = false;
    } else if (amount < 3) {
      setAmountError(`We kindly inform you that the minimum value for this field is ${MinAmount}. 谨此提示 ${MinAmount} 美元出发！`);
      isValid = false;
    } else if (!/^[0-9]+(\.[0-9]{1,2})?$/.test(amount)) {
      setAmountError('Kindly input a valid amount that includes a maximum of two decimal places 最多两位小数');
      isValid = false;
    } else {
      setAmountError('');
    }


    return isValid;
  };

  const chineseTextWeChatPay = '微信支付';
  const chineseTextAliPay = '支付宝';    

  return (

    <div className="auth-form-container">
    <h3>Payments 充值付款</h3>
    {error && <div className="error">{error}</div>}
    {success && <div className="success">{success}</div>}

    {amountError && <div className="error">{amountError}</div>}

    <form className="payment-form" onSubmit={handleSubmit}>

      {/* To ensure cross-browser compatibility, you can add JavaScript validation to check the value of the input field */}
      <label htmlFor="amount">Amount 金额: (USD 美元)</label>
      <input
        type="text"
        id="amount"
        value={amount || AmountString}
        onChange={(event) => setAmount(event.target.value)}
        name="amount"
        pattern="^\d*\.?\d*$"
        min="1"
        required  />

        <button className="auth-form-button contribute" disabled={isProcessing} > {isProcessing ? "Processing 处理中... " : "VISA 支付信用卡 G-Pay LINK"} </button>        

    </form>
    <div>
      <button className="wechatpay-btn" onClick={() => props.onFormSwitch('wechatpay')} > <FontAwesomeIcon icon={faWeixin} /> {chineseTextWeChatPay}</button>
      <button className="alipay-btn" onClick={()=>props.onFormSwitch('alipay')} disabled={isDisabled}> <FontAwesomeIcon icon={faAlipay} />{chineseTextAliPay}</button>
      <button className="apple-btn" onClick={()=>props.onFormSwitch('applepay')} disabled> <FontAwesomeIcon icon={faApplePay} />Apple Pay(soon)</button>
    </div>
    <button className="link-btn" onClick={()=>props.onFormSwitch('login')}>Login 登录</button>    

    <div style={{ maxWidth: "300px", margin: "0 auto" }}>
      <div style={{ marginTop: "1rem" }}>
        <h2>Pay crypto!</h2>                 
        <p>
        USDT accepted at the following address:
        </p>

        <p>0x05e4e1250ad3bbdfb7b04fd929b9224c234e309e</p>
        <img src={usdtQRCode} alt="USDT QR Code" style={{ maxWidth: "100%" }} />
      </div>
    </div>    

  </div>
  );
};




