import React, { useState, useEffect } from "react";
import axios from 'axios';
import { _PDP_ } from './global.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWeixin } from '@fortawesome/free-brands-svg-icons'
import { faAlipay } from '@fortawesome/free-brands-svg-icons';

import { loadStripe } from '@stripe/stripe-js';

const global_DEBUG = false; // make sure set to false - for deployment on GoDaddy; true - for DEBUG
const _PDP2_ = global_DEBUG
  ? `http://localhost:9113`
  : 'https://xplayonline.com:443';


const MinAmount = 19;
const AmountString = `${MinAmount}.00`;

export const AliPay = (props) => {

  const [userID, setUserID] = useState(null);
  const [response, setResponse] = useState(null);
  const [isProcessing, setProcessing] = useState(false);        
  const [publishableKey, setPublishableKey] = useState('');  
  const [amount, setAmount] = useState(AmountString);
  const [name, setName] = useState('');       
  const [amountError, setAmountError] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const userID = await getUserId(props);
      setUserID(userID);
    }
    fetchData();
  }, [props]);

  
  const handleSubmit = async (e) => {
    e.preventDefault();    

    let msgToUser = "";
    const email = props.loggedInUser || localStorage.getItem('existingEmail') || "";    

    // 0) Make sure userID is valid
    if (!Number.isInteger(userID)) {
      msgToUser = `Please log out and in to ensure user ID '${userID}' is valid before paying. 支付前请先登出再登录，确保用户身份有效`;
      setError(msgToUser);
      setSuccess(null);  
      logMessage(msgToUser, email); 
      return;
    }

    // 1) my stripe - upon form is loaded fetch the publishableKey; make sure it is spelled the same as in server

    const { publishableKey } = await fetch(`${_PDP_}/api/config`).then((r) => r.json());
    setPublishableKey(publishableKey);

    if (!publishableKey) {
      setError("The server did not return a Stripe publishable key 服务器未返回 Stripe 公钥.");
      setSuccess(null);      
    }
    // else {
    //   setSuccess("The Stripe publishable API key has been acquired!");
    //   setError(null);
    // }
    const stripe = await loadStripe(publishableKey);

    // 2) 
    if (validateForm()) {
      
      setProcessing(true);

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      };
      
      const data = {
        amount
      };      

      // Make a call to the server to create a new 
      // payment intent and store its clientSecret.
      // The currency provided (usd) is invalid. Payments with alipay support the following currencies: cny, cad.
      const _currency = 'cny';      
      const endpoint = `${_PDP_}/api/create-payment-intent`; 
      const {error: backendError, clientSecret} = await fetch(endpoint, {
          method: 'POST', 
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
              currency: _currency,
              paymentMethodType: 'alipay',
              amount: amount
          })
        }).then((r)=>r.json())

        if(backendError){
            setError(`Error 出错: ${backendError.message}`);
            return;
        }
        setSuccess(`Success 成功: create-payment-intent, clientSecret= ${clientSecret}`);        
        setError(null);


        // Redirect to alipay payment page
        await handleAlipayPayment({clientSecret, amount, name, userID}, stripe);
        return;
    }
  };

  const handleAlipayPayment = async ({clientSecret, amount, name, userID}, stripe) => {
    // function body
    setProcessing(true);
    setError(null);
    setSuccess(null);
  
    try {

      // 1) Confirm the PaymentIntent with our client secret
      const _currency = 'cny';
      const { error: stripeError, paymentIntent } = await stripe.confirmAlipayPayment(clientSecret,  
        {
          payment_method: {
            billing_details:{
              name: name
            }
          },
          return_url: `${_PDP2_}/api/alipay-return?userID=${userID}&amount=${amount}&currency=${_currency}`,
        });
  
      // 2) Test error
      if (stripeError) {
        setError(`Error 出错: ${stripeError.message}`);
        setSuccess(null);
        return;
      }    

      // 3) Not needed - will test before comment it out
      const {error, paymentIntent2} = await stripe.retrievePaymentIntent(
        clientSecret
      );

      if(error){
        setError(`Error 出错: ${error.message}`);
        setSuccess(null);
        return;
      }    

      // 3) creditUserAccount - replaced by return_url above

      // if (paymentIntent.status.toLowerCase().includes("succeeded")) {
      //   setSuccess(`Payment status 支付状态： ${paymentIntent.status} paymentIntentId: ${paymentIntent.id}`);
      //   setError(null);
  
      //   // Credit user account by calling the same success api of US dollars
      //   const currency = 'cny';
      //   if (!Number.isInteger(userID)) {
      //     // Handle the case where userID is not an integer
      //     setError(`User account not credited yet due to invalid user ID ${userID} 由于用户身份无效，支付尚未入帐`);
      //     setSuccess(null);  
      //     return;
      //   }
  
      //   try {
      //     await creditUserAccount(userID, amount, currency);
      //     setSuccess(`Payment credited to user account 支付已存入用户帐户。`);
      //     setError(null);
      //   } catch (error) {
      //     setError(`Error crediting user account 出错: ${error.message}`);
      //     setSuccess(null);
      //     return;
      //   }
      // } else {
      //   alert(`4 Payment Failed 支付失败: ${paymentIntent.status} paymentIntentId: ${paymentIntent.id}`);        
      //   setError(`Payment Failed 支付失败: ${paymentIntent.status} paymentIntentId: ${paymentIntent.id}`);
      //   setSuccess(null);
      // }

      // 4) Reset processing state  
      setProcessing(false);
    } catch (error) {
      console.log(`Error handling Alipay payment: ${error.message}`);
      setProcessing(false);
      setError(`Error handling Alipay payment 出错: ${error.message}`);
    }
  };
    
  const logMessage = async (message, userEmail) => {
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      };

      const endpoint = `${_PDP_}/api/log-message`;          
      const response = await axios.post(endpoint, { message, userEmail }, { headers: headers });
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };    
  

const getUserId = async (props) => {

  try {
    const email = props.loggedInUser || localStorage.getItem('existingEmail') || "";
    const endpoint = `${_PDP_}/api/userid?email=${email}`;
    const response = await axios.get(endpoint);
    setUserID(response.data.userID);
    // return here to avoid Object promise of userID
    return parseInt(response.data.userID);
  } catch (error) {
    console.error(error);          
  }
};

const creditUserAccount = async (userID, amount, currency) => {    
    try {
      const endpoint = `${_PDP_}/api/success`; 
      const { data } = await axios.get(endpoint, {
        params: {
          userID,
          amount,
          currency,
        },
      });
      setResponse(data);
      setSuccess(response.message);
      setError(null);
      // props.setBalance(session.data.balance);
    } catch (error) {
      console.error(error);
      setError(response.message);
      setSuccess(null);
    }
  };  

  const validateForm = () => {
    let isValid = true;

    if (!amount) {
      setAmountError('Please enter an amount 请输入金额');
      isValid = false;
    } else if (amount < MinAmount) {
      setAmountError(`We kindly inform you that the minimum value for this field is ${MinAmount}. 谨此通知 ${MinAmount} 元起付`);
        isValid = false;  
    } else if (amount > 1000000) {
        setAmountError('Please be advised that the maximum value allowed for this field is 1 million 请注意，最大100万.');
        isValid = false;  
    } else if (!/^[0-9]+(\.[0-9]{1,2})?$/.test(amount)) {
      setAmountError('Kindly input a valid amount that includes a maximum of two decimal places 最多两位小数');
      isValid = false;
    } else {
      setAmountError('');
    }


    return isValid;
  };

  const chineseTextWeChatPay = '开始支付宝';  
  const processingText = '处理中 ...'; 

  return (

    <div className="auth-form-container">
    <h3>AliPay 支付宝</h3>
    {error && <div className="error">{error}</div>}
    {success && <div className="success">{success}</div>}
    {amountError && <div className="error">{amountError}</div>}

    <form className="payment-form" onSubmit={handleSubmit}>

        <label htmlFor="name">Username 用户名:</label>
        <input value={name} onChange={(e)=>setName(e.target.value)} type="name" placeholder="username" id="name" name="name" required/> 

        <label htmlFor="amount">Amount 金额: (CNY &#165; 元)</label>        
        <input
        type="text"
        id="amount"
        value={amount || AmountString}
        onChange={(event) => setAmount(event.target.value)}
        name="amount"
        pattern="^\d*\.?\d*$"
        min="8"
        required  />
        <button className="auth-form-button apli-pay" disabled={isProcessing}> {isProcessing ? processingText : chineseTextWeChatPay} </button>

    </form>

    <button className="link-btn" onClick={()=>props.onFormSwitch('login')}>Login 登录</button>    

  </div>
  );
};
