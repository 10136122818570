import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { _PDP_ } from './global.js';

export const DistributorsViewer = (props) => {
    
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [questions, setQuestions] = useState([]);

  const [editingRow, setEditingRow] = useState(null);  
  const [updatedData, setUpdatedData] = useState(questions); // where updatedData is the whole users array; make sure fill with questions otherwise updatedData.map will fail

  const [newUserid, setNewUserid] = useState('');
  const [newTime, setNewTime] = useState('');
  const [newRegCount, setNewRegCount] = useState('');
  const [newPaycount, setNewPaycount] = useState("");
  const [newCntotal, setNewCntotal] = useState("");
  const [newUstotal, setNewUstotal] = useState("");
  const [newInitialbalance, setNewInitialbalance] = useState("");
  const [newCommission, setNewCommission] = useState("");    

  const setEditingRowById  = id => {
    const row = updatedData.find(row => row.id === id);
    setNewUserid(row.userid);
    setNewTime(row.time);
    setNewRegCount(row.regcount);        
    setNewPaycount(row.paycount);    
    setNewCntotal(row.cntotal);        
    setNewUstotal(row.ustotal);        
    setNewInitialbalance(row.initialbalance);    
    setNewCommission(row.commission);        

    setEditingRow(id);
  };  

  const handleSaveClick = async (id, newUserid, newTime, newRegCount, newPaycount, newCntotal, newUstotal, newInitialbalance, newCommission, props ) => {
    const newData = [...updatedData];
    const index = newData.findIndex(row => row.id === id);
    const oldRow = newData[index];
    const newRow = { ...oldRow, userid: newUserid, time: newTime, regcount: newRegCount, paycount: newPaycount, cntotal: newCntotal, ustotal: newUstotal, initialbalance: newInitialbalance, commission: newCommission };
    if (
      newRow.userid !== oldRow.userid || 
      newRow.time !== oldRow.time || 
      newRow.regcount !== oldRow.regcount ||    
      newRow.paycount !== oldRow.paycount ||      
      newRow.cntotal !== oldRow.cntotal ||      
      newRow.ustotal !== oldRow.ustotal ||      
      newRow.initialbalance !== oldRow.initialbalance ||      
      newRow.commission !== oldRow.commission) 
      {
      newData[index] = newRow;
      setUpdatedData(newData);
      setEditingRow(null);

      // where newRow is updated to be pushed to sever

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      };      

      const data =
      {
        id: newRow.id,        
        userid: newRow.userid,
        time: newRow.time,
        regcount: newRow.regcount,    
        paycount: newRow.paycount,
        cntotal: newRow.cntotal,
        ustotal: newRow.ustotal,
        initialbalance: newRow.initialbalance,
        commission: newRow.commission        
      };  
      
      try {    
          const endpoint = `${_PDP_}/api/save-row-distributor`;
          const response = await axios.post(endpoint, data, { headers: headers });
          console.log(response.data);
          setSuccess(response.data.message);
          setError(null);
          props.setBalance(response.data.balance);          

        } catch (error) {
          setError(error.response.data.message);
          setSuccess(null);
        } 
      
      }
  };  

  const renderRow = row => {
    const { id } = row;
    if (editingRow === id) {
      return (
        <tr key={id}>
          <td>{id}</td>          
          <td><input className="input2" type="number" value={newUserid} onChange={e => setNewUserid(e.target.value)} /></td>
          <td><input className="input2" type="text" value={newTime} onChange={e => setNewTime(e.target.value)} /></td> 
          <td><input className="input2" type="number" value={newRegCount} onChange={e => setNewRegCount(e.target.value)} /></td>           
          <td><input className="input2" type="number" value={newPaycount} onChange={e => setNewPaycount(e.target.value)} /></td>          
          <td><input className="input2" type="number" value={newCntotal} onChange={e => setNewCntotal(e.target.value)} /></td>
          <td><input className="input2" type="number" value={newUstotal} onChange={e => setNewUstotal(e.target.value)} /></td>          
          <td><input className="input2" type="number" value={newInitialbalance} onChange={e => setNewInitialbalance(e.target.value)} /></td>
          <td><input className="input2" type="number" value={newCommission} onChange={e => setNewCommission(e.target.value)} /></td>
          <td>
            <button className="save" onClick={() => handleSaveClick(id, newUserid, newTime, newRegCount, newPaycount, newCntotal, newUstotal, newInitialbalance, newCommission, props)}>Save</button>
            <button className="cancel" onClick={() => setEditingRow(null)}>Cancel</button>            
          </td>

        </tr>
      );
    } else {
      return (
        <tr key={id}>
          <td>{row.id}</td>
          <td>{row.userid}</td>
          <td>{row.time}</td>
          <td>{row.regcount}</td>
          <td>{row.paycount}</td>
          <td>{row.cntotal}</td>
          <td>{row.ustotal}</td>
          <td>{row.initialbalance}</td>
          <td>{row.commission}</td>
          <td>
            <button className="edit" onClick={() => setEditingRowById(id)}>Edit</button>            
          </td>
        </tr>
      );
    }
  };
  
  useEffect(() => {
    fetchRecords();
  }, []);


  const fetchRecords = async () => {

    const endpoint = `${_PDP_}/api/distributors` ;
    const response = await fetch (endpoint, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem('token')}`, // include token in header        
      }
    });    

    if (response.ok) {
      const data = await response.json();      
      console.log(data.message);
      setSuccess(data.message);        
      setError(null);        
      setQuestions(data);
      setUpdatedData(data);      
    } else {
      // handle error
      const msgToUser = `Please log in to get the required authentication. Access Status: ${response.status} - ${response.statusText}.`;      
      console.log(msgToUser);
      setSuccess(null);        
      setError(msgToUser);        
    }        
  };      

  const refreshRecords = () => {
    fetchRecords();
  }

  return (
    <div className="auth-form-container">
    <h3>Distributors Viewer</h3>   
    {error && <div className="error">{error}</div>}
    {success && <div className="success">{success}</div>}

    <div className="table-container">
      <table className="table-form">
        <thead>
          <tr>
            <th>ID</th>
            <th>Userid</th>
            <th>Time</th>
            <th>RegCount</th>
            <th>Paycount</th>
            <th>Cntotal(¥)</th>
            <th>Ustotal($)</th>
            <th>InitialBalance</th>
            <th>Commission</th>
          </tr>
        </thead>
        <tbody>
          {updatedData.map(renderRow)}
        </tbody>
      </table>
    </div>
    <div style={{ marginTop: '20px' }}>
      <button className="link-btn" onClick={refreshRecords}>Refresh</button>                 
      <button className="link-btn" onClick={()=>props.onFormSwitch('login')}>Login</button>             
    </div>
    </div>
    );  
}